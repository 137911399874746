import { Song } from '@careaoke/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { has, get, divide } from 'lodash';
import { useKaraokeDownload } from '../../hooks/karaokeDownload';
import { CanvasSlides } from '../../components/KaraokeSlides/CanvasSlides';
import { Player as PlayerProgress } from '../../components/Player/Player';
import { useLyricProcessor } from '../../hooks/lyric';
import { useEventsPlayer, useTimelineEvents } from '../../hooks/TimelineEvents';
import { Button, Container, Icon, Flex, Spinner } from '@chakra-ui/react';
import { MdFullscreen, MdMusicNote } from 'react-icons/md';

interface PlayerProps {
  selectedSong: Song;
  nextSong: Song;
  onPlay: () => void;
  onPause: () => void;
  setSongFinished: (isFinished: boolean) => void;
  onSongLoaded: () => void;
  onUpdateTime: (time: number) => void;
  renderToolbar: () => React.ReactElement;
  onMusicStop: () => void;
}

interface Instructions {
  [key: number]: { highlightNextWord: boolean; type: 'HIGHLIGHT_NEXT_WORD' }[];
}

interface ResourceAudio {
  src: string;
  blog: Blob;
}
interface Resource {
  audio: ResourceAudio;
  audioWithLyrics: ResourceAudio;
  instructions: Instructions;
  lyrics: string;
}

interface UseKaraokeDownloadProps {
  startDownload: ({ url }: { url: string }) => void;
  data: Resource;
  loading: boolean;
  progress: number;
  processing: boolean;
  error: Error;
  finished: boolean;
}

export const Player = ({
  selectedSong,
  renderToolbar,
  onMusicStop,
  onUpdateTime,
  setSongFinished,
  onPlay,
  onPause,
}: PlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioWithLyricsRef = useRef<HTMLAudioElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);

  const openFullscreen = useCallback(() => {
    if (!frameRef.current) return;
    frameRef.current.requestFullscreen();
  }, []);
  const [cheatMode, setCheatMode] = useState(false);

  const { current } = audioRef;
  const { current: currentWithLyrics } = audioWithLyricsRef;
  const counterProps = useMemo(() => ({ autoStart: false }), []);
  const { songId } = selectedSong;

  const { setSong } = useLyricProcessor() as {
    setSong: ({ resource, song }: { resource: Resource; song: Song }) => void;
  };

  const { play, pause } = useEventsPlayer({
    audioRef,
    onEvent({ time }: { time: number }) {
      onUpdateTime && onUpdateTime(time);
    },
    onStop() {
      onMusicStop && onMusicStop();
    },
  });

  const playerActions = useMemo(() => ({ play, pause }), [songId]);

  const {
    startDownload,
    data: resource,
    loading,
  }: UseKaraokeDownloadProps = useKaraokeDownload();

  const { fileUrl } = selectedSong;
  useEffect(() => {
    if (!fileUrl) return;
    startDownload({ url: fileUrl });
  }, [fileUrl]);

  // Keeps both audio refs in sync Play/Pause and stop
  useEffect(() => {
    if (!current || !currentWithLyrics) return;
    current.addEventListener('play', () => {
      currentWithLyrics?.play();
      onPlay && onPlay();
    });
    current.addEventListener('pause', () => {
      currentWithLyrics?.pause();
      onPause && onPause();
    });
  }, [current, currentWithLyrics]);

  useEffect(() => {
    if (current) {
      current.addEventListener('ended', () => {
        setSongFinished && setSongFinished(true);
      })
    }
  }, [current])

  const hasSongWithLyrics = useMemo(
    () => has(resource, 'audioWithLyrics'),
    [resource]
  );

  // Load the song
  useEffect(() => {
    setSong({ resource, song: selectedSong });
  }, [resource, songId]);

  // Pause the audio if new karaoke is being loaded
  useEffect(() => {
    if (loading && current) current.pause();
  }, [loading, current]);

  const toggleCheatMode = useCallback(() => {
    if (!current || !currentWithLyrics) return;

    current.muted = !cheatMode;
    currentWithLyrics.muted = cheatMode;

    setCheatMode(!cheatMode);
  }, [cheatMode, current, currentWithLyrics]);

  return (
    <Container minW="fit-content" ref={frameRef} paddingX={0}>
      <CanvasSlides loading={loading} selectedSong={selectedSong} counterProps={counterProps} playerActions={playerActions} />
      <Flex
        justifyContent="space-between"
        position="absolute"
        bottom="1em"
        width="full"
        height="16"
        paddingX={4}
      >
        <PlayerProgress audio={audioRef} />
        <Flex gridGap={4} alignItems="center" justifyContent="center" pl={4}>
          <Button
            leftIcon={
              <Icon as={MdMusicNote} fontSize={32} title="Toggle Cheat Mode" />
            }
            disabled={!hasSongWithLyrics}
            onClick={() => toggleCheatMode()}
          >{`Cheat Mode ${cheatMode ? 'On' : 'Off'}`}</Button>
          <Button
            leftIcon={
              <Icon as={MdFullscreen} fontSize={32} onClick={openFullscreen} />
            }
          >
            Full Screen
          </Button>
          {renderToolbar()}
        </Flex>
      </Flex>
      <audio
        ref={audioRef}
        controls={false}
        className="form-audio"
        src={get(resource, ['audio', 'src'])}
      />
      <audio
        ref={audioWithLyricsRef}
        controls={false}
        className="form-audio"
        src={get(resource, ['audioWithLyrics', 'src'])}
        muted
      />
      {loading && 
      <Flex
      zIndex="100"
      top="0"
      position="absolute"
      width="100vw"
      height="100vh"
      background="rgba(100,100,100,0.5)"
      justifyContent="center"
      alignItems="center"
      >
        <Spinner size="xl" color="white"/>
      </Flex>}
    </Container>
  );
};
