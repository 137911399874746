import { useEffect, useState, useCallback } from 'react'
import { Flex, Progress } from '@chakra-ui/react'
import './styles.scss'

export const Player = ({ audio }) => {
  const [percent, setPercent] = useState(0)
  const timeUpdated = useCallback(({ target }) => {
    const { currentTime, duration } = target

    setPercent((currentTime * 100) / duration)
  }, [])

  useEffect(() => {
    if (!audio.current) return

    audio.current.addEventListener('timeupdate', timeUpdated)
  }, [audio])

  return (
    <Flex width="100%" alignItems="center">
      <Progress
        value={percent}
        width="full"
        height={2}
        borderRadius={3}
      />
    </Flex>
  )
}
