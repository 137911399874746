import { useState, useEffect, useCallback } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { firestore, database } from '../../index';
import { usePlaylistStore } from '../usePlaylist/usePlaylist';
import { Event } from '@careaoke/types';

export const useEvent = (eventId: string) => {
  const [percentage, setPercentage] = useState(0);

  const [value, loading, error] = useDocument<Event>(
    firestore.doc(`event/${eventId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const event = value?.data();
    if (event?.currentDonation && event?.target)
      setPercentage(Math.round((event?.currentDonation / event?.target) * 100));
  }, [value]);

  const incrementCurrent = (number: number) => {
    const event = value?.data();
    const ref = firestore.collection('event').doc(eventId);
    ref.update({ currentDonation: (event?.currentDonation || 0) + number });
  };

  return {
    event: value?.data(),
    percentage,
    loading,
    error,
    incrementCurrent,
  };
};

export const usePlayer = () => {
  const selectedSong = usePlaylistStore((state) => state.selectedSong);

  const updateDoc = useCallback(
    ({ eventId, time }) => {
      if (!selectedSong.songId) return;

      database
        .ref(`event/${eventId}`)
        .update({ id: selectedSong.songId, time: time || 0, playing: !!time });
    },
    [selectedSong.songId]
  );

  const togglePlaying = ({
    eventId,
    playing,
  }: {
    eventId?: string;
    playing?: boolean;
  }) => {
    database.ref(`event/${eventId}`).update({ playing });
  };

  return {
    updateDoc,
    togglePlaying,
  };
};

export type UseEventReturn = ReturnType<typeof useEvent>;

export default useEvent;
