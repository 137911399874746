import { ReactElement } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

export interface UiProviderProps {
  children?: React.ReactNode;
}

/**
 * Theme provider provided by Chakra UI
 * @param props
 * @returns
 */
export function UiProvider(
  props: UiProviderProps
): ReactElement<UiProviderProps> {
  return (
    <ChakraProvider {...props}>
      {props.children}
    </ChakraProvider>
  );
}

export default UiProvider;
