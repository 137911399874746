import { useEffect, useRef } from 'react';
import { useLyricHighlighter, useLyricProcessor } from 'hooks/lyric';
import { Stage, Layer, Group } from 'react-konva';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { useAnimatedText } from './useAnimatedText';
import { BIG_CANVAS_PROPS } from '../../constans/konva';

import { Background } from './Background';
import { ActionsLayer } from './ActionsLayer';
import { Logo } from './Logo';

export const CanvasSlides = ({
  width = 0,
  counterProps,
  playerActions,
  selectedSong,
  loading = false,
}) => {
  const { innerWidth = 0, innerHeight = 0 } = useWindowDimensions();

  const responsiveWidth = innerWidth;
  const responsiveHeight = innerHeight;

  const highlighRef = useRef();
  const layerRef = useRef();

  const { currentVerse, currentSong } = useLyricProcessor();
  const { setStageRef } = useLyricHighlighter();

  useAnimatedText({ layerRef, currentVerse });

  useEffect(() => {
    setStageRef(layerRef);
  }, [layerRef]);

  return (
    <Stage
      width={responsiveWidth}
      height={responsiveHeight}
      style={{ '--window-w': window.outerWidth, '--canvas-w': width }}
    >
      <Layer ref={layerRef}>
        <Background width={responsiveWidth} height={responsiveHeight} />
        <Logo width={500} x={40} y={40} />
        <Group
          id="base-lyric"
          width={responsiveWidth}
          height={responsiveHeight}
        ></Group>
        <Group
          id="highlited-lyric"
          ref={highlighRef}
          width={responsiveWidth}
          height={responsiveHeight}
        ></Group>
      </Layer>
      <ActionsLayer
        selectedSong={selectedSong}
        counterProps={counterProps}
        playerActions={playerActions}
        width={responsiveWidth}
        height={responsiveHeight}
        currentSong={currentSong}
        loading={loading}
      />
    </Stage>
  );
};

CanvasSlides.defaultProps = {
  height: BIG_CANVAS_PROPS.height,
  width: BIG_CANVAS_PROPS.width,
  useCounter: false,
  counterProps: {},
};
