// import AWS from 'aws-sdk'
import { useCallback, useReducer } from 'react'
import { floor, last, merge, split, isEmpty } from 'lodash'
import { zip } from '@lgrignon/zip.js/WebContent/zip.js' // this should be local version of zip but need more time to debug
import { Inflater } from '@lgrignon/zip.js/WebContent/inflate.js'

import { reducerMerger } from 'utils/hook'
import { KR_FILES_NAMES } from 'constans/kr'

zip.Inflater = Inflater
zip.useWebWorkers = false

export const useKaraokeDownload = () => {
  const [state, setState] = useReducer(reducerMerger, {
    data: null,
    progress: 0,
    finished: false,
    loading: false,
    error: null,
    processing: false,
  })

  const startDownload = useCallback(async ({ url }) => {
    if (!url) return

    // This is an Google drive url, not KR link
    if (!isEmpty(url.match('drive.google'))) {
      window.open(url)
      return
    }

    setState({ loading: true, error: null })

    // Resource url is from S3
    if (false) {
      //url.search(`https://${krBucketName}`) > -1){
      const name = url.replace(`https://${krBucketName}.s3.amazonaws.com/`, '')

      const krDownload = new AWS.S3().getObject({
        Bucket: krBucketName,
        Key: name,
      })

      krDownload.on('httpDownloadProgress', (req) => {
        const progress = (req.loaded / req.total) * 100
        console.log('Progress', req)
        setState({ progress })
      })

      try {
        const data = await krDownload.promise()
        setState({ finished: true, data, loading: false })
        // All finished
        console.log('Successfully downloaded this karaoke', data)
      } catch (error) {
        console.error(error)
        alert('Error downloading karaoke: ', error.name)
        setState({ loading: false, error: error.name })
      }
    }
    // From any other Resource url
    else {
      const request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('GET', url, true)
      // request.setRequestHeader('Origin', '*')
      request.setRequestHeader('Content-Type', 'binary/octet-stream')
      request.send()

      request.onprogress = function (req) {
        const progress = floor((req.loaded / req.total) * 100)
        setState({ progress })
      }
      request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          setState({ processing: true })

          handleKRBlob(this.response)
            .then((data) => {
              setState({
                loading: false,
                finished: true,
                data,
                processing: false,
              })
            })
            .catch((error) => {
              console.error(error)
              setState({ loading: false, finished: true, error })
            })
        }
        if (this.readyState == 4 && this.status !== 200) {
          const error = `Feth data error: ${this.statusText}`
          setState({
            loading: false,
            finished: false,
            data: null,
            processing: false,
            error,
          })
        }
      }
    }
  }, [])

  return {
    ...state,
    startDownload,
  }
}
const handleKRBlob = async (dataBlob) => {
  const task = []

  const { entries, reader } = await readZipBlob(dataBlob)
  entries.forEach((entry) => {
    if (entry.directory) return
    task.push(readEntry(entry))
  })

  const resources = await Promise.all(task)
  reader.close(() => {})

  return merge({}, ...resources)
}

const handleResouce = async (entry, response) => {
  const nameExt = last(split(entry.filename, '/'))
  const [name, extension] = split(nameExt, '.')
  let reader = null
  switch (name) {
    case 'instructions':
      reader = new zip.TextWriter()
      break
    case KR_FILES_NAMES.defaultSong:
      reader = new zip.BlobWriter()
      break
    case KR_FILES_NAMES.withLyrics:
      reader = new zip.BlobWriter()
      break
    default:
      break
  }
  if (!reader) return Promise.resolve({})

  return new Promise((res, rej) => {
    entry.getData(reader, (data) => {
      switch (name) {
        case 'instructions':
          const { instructions, lyrics } = JSON.parse(data)
          res({
            instructions,
            lyrics,
          })
          break
        case KR_FILES_NAMES.defaultSong:
          res({
            audio: {
              src: URL.createObjectURL(data),
              blob: data,
            },
          })
          break
        case KR_FILES_NAMES.withLyrics:
          res({
            audioWithLyrics: {
              src: URL.createObjectURL(data),
              blob: data,
            },
          })
          break
        default:
          res({})
          break
      }
    })
  })
}
const readZipBlob = (dataBlob) => {
  return new Promise((res, rej) => {
    zip.createReader(new zip.BlobReader(dataBlob), (reader) => {
      reader.getEntries((entries) => {
        res({ entries, reader })
      })
    })
  })
}
const readEntry = (entry, zipFile) => {
  return new Promise((res, rej) => {
    handleResouce(entry).then(res).catch(rej)
  })
}
