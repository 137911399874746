import { StandaloneLayoutPlayer } from '@careaoke/player'
import { StandaloneSearch } from '../StandaloneSearch'
import { useDisclosure, ModalCloseButton } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';

import { 
  Flex, 
  Button, 
  Modal, 
  Input, 
  Text 
} from '@careaoke/ui'

export const StandalonePlayer = () => {
  const { songId }: { songId: string } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <StandaloneLayoutPlayer onOpen={onOpen}/>
      <Modal
        isOpen={!songId || isOpen}
        onCancel={onClose}
        scrollBehavior="inside"
        size="full"
        isCentered
      >
        <ModalCloseButton/>
        <StandaloneSearch modal modalOnClose={onClose}/>
      </Modal>
    </>
  )
}
