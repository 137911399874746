import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdPlaylistPlay } from 'react-icons/md';
import { FaUserFriends } from 'react-icons/fa';


import {
  useNowPlaying,
  usePlaylist,
  usePlaylistStore,
  usePlayer,
} from '@careaoke/api';
import { 
  Flex, 
  Button, 
  Icon
} from '@careaoke/ui'
import './styles.scss';

import { Player } from './Player';
import { YoutubePlayer } from './YoutubePlayer';

export const LayoutPlayer = (props) => {
  const { eventId } = useParams();
  const { playNextSong, songs, playThisSong } = usePlaylist(eventId);
  const { updateDoc, togglePlaying } = usePlayer();
  const { id: songId } = useNowPlaying(eventId);

  const selectedSong = usePlaylistStore((state) => state.selectedSong);
  const [songFinished, setSongFinished] = useState(false);

  // sets selected song based on last played in realtime db if none is selected
  useEffect(() => {
    if (!selectedSong?.fileUrl && songs?.length) {
      playThisSong(songId)
    }
  }, [songs, songId])

  // last song finished and new song has been set, let db know to reset timer
  const { fileUrl } = selectedSong;
  useEffect(() => {
    if (!fileUrl) return;
    setSongFinished && setSongFinished(false)
    updateTime(0)
  }, [fileUrl]);

  // Song is finished
  useEffect(() => {
    if (songFinished) {
      playNextSong(selectedSong.index); 
    }
  }, [songFinished, songs.length]);

  const updateTime = useCallback(
    (time) => updateDoc({ eventId, time, playing: true }),
    [updateDoc, eventId]
  );

  const Component = useMemo(() => {
    return fileUrl?.includes('.kr') ? Player : YoutubePlayer
  }, [fileUrl])

  return (
    <Component
      selectedSong={selectedSong}
      renderToolbar={() => (
        <Flex 
          gridGap={4} 
          alignItems="center" 
          justifyContent="center" 
        >
          <Button
            leftIcon={<Icon as={FaUserFriends} fontSize={32} color="black" />}
            onClick={props.inviteModalOnOpen}
          >
            Invite Friends
          </Button>
          <Button
            leftIcon={<Icon as={MdPlaylistPlay} fontSize={32} color="black" />}
            onClick={props.drawerOnOpen}
          >
            Playlist
          </Button>
        </Flex>
      )}
      onUpdateTime={(time) => updateTime(time)}
      onPlay={() => togglePlaying({ eventId, playing: true })}
      onPause={() => togglePlaying({ eventId, playing: false })}
      onMusicStop={() => togglePlaying({ eventId, playing: false })}
      setSongFinished={setSongFinished}
    />
  );
};
