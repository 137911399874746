import moment from 'moment'
import { Flex, Spacer } from '@chakra-ui/layout';
import { MyAvatar, Text } from '@careaoke/ui'
import { Event } from '@careaoke/types'

// eslint-disable-next-line
export interface EventInfoProps {
  event: Event,
  user?: string
}

export const EventInfo = ({ event, user }: EventInfoProps) => {
  const { title, description, startdate, minimumDonation } = event
  return (
    <>
      <Flex justifyContent="space-between">
        <Text fontSize="1.5em" fontWeight="bold">{title}</Text>
        <MyAvatar isMine user={user}/>
      </Flex>
      <Text fontSize="0.8em" style={{marginBottom: 10}}>{description}</Text> 
      <Flex>
        {/* <Text fontSize="0.8em">Holly</Text> */}
        {/* <Spacer/> */}
        <Text fontSize="0.8em">{moment(startdate).format("MMM Do YY")}</Text>
        <Spacer/>
        {false && <Text fontSize="0.8em">min: ${minimumDonation}</Text>}
      </Flex>
    </>
  )
};

export default EventInfo;
