import firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

import { FIREBASE } from 'config/env';

firebaseApp.apps.length === 0 && firebaseApp.initializeApp(FIREBASE);

export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
export const database = firebaseApp.database();
export const storage = firebaseApp.storage();

export * from './lib/api';
export * from './lib/useAuth/useAuth';
export * from './lib/useFirestore/useFirestore';
export * from './lib/useSearch/useSearch';
export * from './lib/useSearch/useSearchFacets';
export * from './lib/useSearch/useFacetSearch';
export * from './lib/useSearch/useSong';
export * from './lib/useSearch/useSearch';
export * from './lib/useEvent/useEvent';
export * from './lib/useEvent/useCreateEvent';
export * from './lib/usePlaylist/usePlaylist';
