export const FacetOptions = [
  'artist_name',
  'genre',
  'album_release_year',
  'creator_brand_name'
] as const;

export type FacetOptions = typeof FacetOptions[number];

export const FacetNames: Record<FacetOptions, string> = {
  artist_name: 'Artist',
  genre: 'Genre',
  album_release_year: 'Year',
  creator_brand_name: 'Brand'
};

export interface FacetDict {
  [letter: string]: { label: string; count: number }[];
}

export interface SearchHit {
  name: string;
  duration: number;
  artist_name: string;
  lyrics: string;
  file_url: string;
  id: number;
}

export type FacetData = {
  [key in FacetOptions]: { [label: string]: number };
}

export interface Hit {
  name: string;
  duration: number;
  artist_name: string;
  lyrics: string;
  file_url: string;
  id: number;
  album_name: string;
  album_release_year: string;
  creator_brand_name?: string;
}
