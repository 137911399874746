import { LayoutPlayer } from '@careaoke/player'
import { useDisclosure, useClipboard } from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom';
import { PlayerDrawer } from './PlayerDrawer'
import { useEvent } from '@careaoke/api'

import { 
  Flex, 
  Button, 
  Modal, 
  Input, 
  Text,
  Center,
  Spinner
} from '@careaoke/ui'

export const Player = () => {
  const { eventId }: { eventId: string } = useParams();
  const { event, loading } = useEvent(eventId)
  const history = useHistory()

  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure()
  const { isOpen: inviteModalIsOpen, onOpen: inviteModalOnOpen, onClose: inviteModalOnClose } = useDisclosure()

  const inviteUrl = `${window.location.origin}/event/${eventId}`
  const { hasCopied, onCopy } = useClipboard(inviteUrl)
  if (loading)
    return (
      <Center style={{ padding: 10 }}>
        <Spinner size="xl" />
      </Center>
    );
  if (!event) history.push('/')

  return (
    <>
      <LayoutPlayer drawerOnOpen={drawerOnOpen} inviteModalOnOpen={inviteModalOnOpen}/>
      <PlayerDrawer onClose={drawerOnClose} isOpen={drawerIsOpen} />
      <Modal
        isOpen={inviteModalIsOpen}
        onCancel={inviteModalOnClose}
        isCentered
      >
        <Text fontWeight="bold" mb="2">Text this link to your friends to join the party and add songs too!</Text>
        <Flex>
          <Input value={inviteUrl} isReadOnly placeholder='Welcome' />
          <Button onClick={onCopy} ml={2}>
            {hasCopied ? 'Copied' : 'Copy'}
          </Button>
        </Flex>
      </Modal>
    </>
  )
}
