import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
} from 'react';
import {
  split,
  reject,
  isEmpty,
  map,
  size,
  reduce,
  slice,
  isInteger,
  join,
  get,
} from 'lodash';
import { _EVENTS, useEventListener } from 'utils/EventEmitter';
import { reducerMerger } from 'utils/hook';
import { createTextLine, createKonvaVerse } from 'utils/konva';
import { BIG_CANVAS_PROPS, CANVAS_PADDING } from 'constans/konva';
import { useSettings } from './settings';
import { useTimelineEvents } from './TimelineEvents';

export const _LYRIC_DESCRIPTION = `Add some lyrics here.

Hit "ENTER" twice to create a new slide.`;

const testTextLine = createTextLine('');
const CANVAS_TEXT_WIDTH = BIG_CANVAS_PROPS.width - CANVAS_PADDING;
const processTextLine = (acc, line) => {
  let textLine = line.split(' ');
  const { width, height } = testTextLine.measureSize(line);

  // Text in the line is have too many words
  if (width > CANVAS_TEXT_WIDTH) {
    let textWords = [];
    const lines = reduce(
      textLine,
      (acc, word) => {
        const addedText = [...textWords, word];
        const { width } = testTextLine.measureSize(addedText.join(' '));

        if (width > CANVAS_TEXT_WIDTH) {
          const nextState = [...acc, textWords];
          textWords = [word];
          return nextState;
        }
        textWords = addedText;
        return acc;
      },
      acc
    );
    return [...lines, textWords];
  }

  return [...acc, textLine];
};

export const useProcessor = () => {
  const [state, setState] = useState({ items: [], lyric: '' });

  const setLyric = useCallback((text) => {
    const verses = map(reject(split(text, /\n\n/), isEmpty), (verse, index) => {
      const textLines = reduce(verse.split(/[\n]/), processTextLine, []);
      return {
        text: verse,
        name: `Slide ${index + 1}`,
        textLines,
        words: verse.split(/[\n ]/),
        numberOfLines: textLines.length,
        index,
      };
    });

    setState({ items: verses, lyric: text });
  }, []);

  return {
    ...state,
    lyrics: state.lyric,
    setLyric,
  };
};

const LyricProcessorContext = createContext({});

export const useLyricProcessor = () => {
  return useContext(LyricProcessorContext);
};

const initState = {
  currentPosition: null,
  prevPosition: null,
  currentVerse: null,
  verseHighlited: '',
  positionHighlited: null,
};

const nextVerserUpdater =
  ({ position, items }) =>
  (state) => {
    if (position === state.currentPosition) return state;

    const nextState = { ...state };
    if (!isInteger(position))
      nextState.currentPosition = isInteger(nextState.currentPosition)
        ? nextState.currentPosition + 1
        : 0;
    else nextState.currentPosition = position;

    if (
      nextState.currentPosition < 0 ||
      nextState.currentPosition > size(items)
    )
      nextState.currentPosition = 0;

    nextState.prevPosition = state.currentPosition;
    nextState.currentVerse = items[nextState.currentPosition];

    return nextState;
  };

export const LyricProcessorProvider = ({ children }) => {
  const autoVerseChange = useRef();

  const [state, setState] = useState(initState);
  const { items, setLyric, lyrics } = useProcessor();
  const internalState = useRef({ items, lyrics });
  const { resetTimelineEvents } = useTimelineEvents();
  const [currentSong, setCurrentSong] = useState();

  const nextVerse = useCallback((position) => {
    const items = internalState.current.items;
    // Cancel any programmed auto change of verse.
    clearTimeout(autoVerseChange.current);

    setState(nextVerserUpdater({ position, items }));
  }, []);

  const restartLyricProcessor = useCallback(() => {
    nextVerse(0);
  }, [nextVerse]);

  const selectVerse = useCallback(
    (position) => {
      if (isFinite(position)) {
        nextVerse(position);
      } else {
        restartLyricProcessor();
      }
    },
    [nextVerse, restartLyricProcessor]
  );
  // const undoLastEvent = useCallback(() => {}, [selectVerse])

  const resetLyricProcessor = useCallback((lyrics = _LYRIC_DESCRIPTION) => {
    setLyric(lyrics);
    // setState(initState)
  }, []);
  const changeVerse = useCallback(
    (verse) => {
      if (verse === null) restartLyricProcessor();
      else selectVerse(verse.index);
    },
    [selectVerse]
  );

  useEventListener(_EVENTS.VERSE_CHANGE, changeVerse);
  useEventListener(_EVENTS.NEXT_VERSE, nextVerse);
  useEventListener(_EVENTS.RESTART_KARAOKE, restartLyricProcessor);
  // useEventListener(_EVENTS.UNDO_HIGHLIGHT_WORD, undoLastEvent)

  useEffect(() => {
    restartLyricProcessor();
    internalState.current = { lyrics, items };
  }, [lyrics]);

  useEffect(() => {
    setLyric('...');
  }, []);

  const currentVerse = useMemo(
    () => items[state.currentPosition],
    [state.currentPosition, items],
    [items]
  );

  const setSong = useCallback(({ resource, song }) => {
    setCurrentSong(song);
    if (!resource) return;
    // Set the lyrics text into processor
    if (resource.lyrics) resetLyricProcessor(resource.lyrics);
    // Set the event to play
    if (resource.instructions) resetTimelineEvents(resource.instructions);
  }, []);

  const value = {
    ...state,
    currentVerse,
    setState,
    nextVerse,
    items,
    setLyric,
    lyrics,
    selectVerse,
    restartLyricProcessor,
    resetLyricProcessor,
    setSong,
    currentSong,
  };

  return (
    <LyricProcessorContext.Provider value={value}>
      {children}
    </LyricProcessorContext.Provider>
  );
};

const initHighlited = {
  currentLine: null,
  currentPosition: null,
  highlightedTextLine: '',
};
export const useLyricHighlighter = () => {
  const layer = useRef();
  const stageRef = useRef();
  const konvaRefs = useRef();
  const currentVerse = useRef();
  const internalItems = useRef([]);
  const { highlightedLyricsText } = useSettings();
  const highlightedLyricsTextIn = useRef(highlightedLyricsText);
  const [nextVerseData, setNextVerseData] = useState({});

  const { nextVerse, ...lyricsProcessor } = useLyricProcessor();
  const [highlighted, setHighlighted] = useReducer(
    reducerMerger,
    initHighlited
  );

  useEffect(() => {
    if (nextVerseData.text) {
      nextVerse(true);
      currentVerse.current = nextVerseData;
      clearStage(stageRef.current, false);
      renderKonvaVerse();
    }
  }, [nextVerseData]);

  const setStageRef = useCallback((ref) => {
    // stageRef.current = ref.current
    layer.current = ref.current;
  }, []);

  const highlightWordIndex = useCallback((state) => {
    const { currentPosition, currentLine, highlightedTextLine } = state;
    if (
      !currentVerse.current ||
      !isFinite(currentPosition) ||
      !konvaRefs.current
    )
      return state;

    const { textBoxes, group } = konvaRefs.current;
    const textBox = textBoxes[currentLine];
    if (textBox) {
      textBox.text(highlightedTextLine);
      layer.current.draw();
    }
    return;
  }, []);
  const handleNextVerse = useCallback(() => {
    const items = internalItems.current;
    if (!currentVerse.current) verseChange(items[0]);
    else verseChange(items[currentVerse.current.index + 1]);
  }, []);

  const highlightNextWord = useCallback(() => {
    setHighlighted((highlightedState) => {
      const items = internalItems.current;
      if (!currentVerse.current) return highlightedState;

      let { textLines } = currentVerse.current;
      const { currentLine, currentPosition } = highlightedState;
      const newHighlightedState = { ...highlightedState };

      // Move to the next working word position
      newHighlightedState.currentPosition =
        currentPosition == null ? 0 : currentPosition + 1;

      // Move to next working line, if word position reach the end of line
      newHighlightedState.currentLine =
        currentLine === null
          ? 0
          : newHighlightedState.currentPosition >= size(textLines[currentLine])
          ? currentLine + 1
          : currentLine;

      // Working word position exceed line, End of line
      const endOfWords =
        newHighlightedState.currentPosition >= size(textLines[currentLine]);
      if (endOfWords) newHighlightedState.currentPosition = 0;

      // Working line position exceed max line, End of verse
      const endOfLines = newHighlightedState.currentLine >= size(textLines);
      if (endOfLines && endOfWords) {
        const nextVerseIndex = currentVerse.current.index + 1;
        if (nextVerseIndex >= size(items)) return;

        // Automatically dispatch next verse event
        setNextVerseData(items[nextVerseIndex]);

        // Reader virtual Konva
        // clearStage(stageRef.current, false)
        // renderKonvaVerse()

        // Continue Highliting the word if next verse
        newHighlightedState.currentLine = 0;
        newHighlightedState.currentPosition = 0;
        textLines = items[nextVerseIndex].textLines;
      }

      // Render/update Canvas Highlighted text in the line
      const textLine = textLines[newHighlightedState.currentLine];
      newHighlightedState.highlightedTextLine = join(
        slice(textLine, 0, newHighlightedState.currentPosition + 1),
        ' '
      );

      highlightWordIndex(newHighlightedState);

      return newHighlightedState;
    });
  }, [handleNextVerse]);

  const undoLastEvent = useCallback(() => {
    setHighlighted((highlightedState) => {
      if (!currentVerse.current) return highlightedState;

      const { textLines } = currentVerse.current;
      const { currentLine, currentPosition } = highlightedState;
      const newHighlightedState = { ...highlightedState };

      // Do nothing, LyricProcessor will handle if go back to prev Verse is needed
      if (currentLine === null || currentPosition === null)
        return highlightedState;

      // Move back to prev working line, if word position is none
      newHighlightedState.currentLine =
        currentPosition - 1 < 0 && currentLine - 1 >= 0
          ? currentLine - 1
          : currentLine;

      // Move back, current word
      newHighlightedState.currentPosition = currentPosition - 1;
      if (newHighlightedState.currentPosition < 0) {
        newHighlightedState.highlightedTextLine = '';

        // Render/update Canvas; Clean prev Highlighted line
        highlightWordIndex({ ...newHighlightedState, currentLine });
      } else {
        const textLine = textLines[newHighlightedState.currentLine];
        newHighlightedState.highlightedTextLine = join(
          slice(textLine, 0, newHighlightedState.currentPosition + 1),
          ' '
        );
        // Render/update Canvas Highlighted text in the line
        highlightWordIndex(newHighlightedState);
      }

      if (newHighlightedState.currentPosition < 0) {
        if (currentLine === newHighlightedState.currentLine) {
          newHighlightedState.currentLine = null;
          newHighlightedState.currentPosition = null;
        } else {
          newHighlightedState.currentPosition =
            size(textLines[newHighlightedState.currentLine]) - 1;
        }
      }

      return newHighlightedState;
    });
  }, []);

  const verseChange = useCallback((verse, verseState = initHighlited) => {
    currentVerse.current = verse;
    setHighlighted(verseState);

    clearStage();
    if (currentVerse.current) renderKonvaVerse();
    if (layer.current) layer.current.draw();
  }, []);
  const restartLyricHighliter = useCallback(() => {
    verseChange(internalItems.current[0]);
  }, []);

  const renderKonvaVerse = useCallback(() => {
    if (!currentVerse.current || !layer.current) return;

    const [container] = layer.current.getChildren(
      (node) => node.getId() === 'highlited-lyric'
    );
    const textProps = highlightedLyricsTextIn.current;
    konvaRefs.current = createKonvaVerse(container, currentVerse.current, {
      textProps,
    });
  }, []);
  const clearStage = useCallback(() => {
    if (!konvaRefs.current) return;

    const { group } = konvaRefs.current;
    group.destroy();
    // layer.current.draw()
  }, []);
  const reRenderKonvaVerse = useCallback(() => {
    // clearStage()
    renderKonvaVerse();
  }, []);

  const resetLyricHighlighter = useCallback(
    ({ lyrics, items, currentVerse }) => {
      internalItems.current = items;
      verseChange(currentVerse);
      restartLyricHighliter();
    },
    []
  );

  useEventListener(_EVENTS.HIGHLIGHT_NEXT_WORD, highlightNextWord);
  useEventListener(_EVENTS.VERSE_CHANGE, verseChange);
  useEventListener(_EVENTS.NEXT_VERSE, handleNextVerse);
  useEventListener(_EVENTS.RESTART_KARAOKE, restartLyricHighliter);
  useEventListener(_EVENTS.UNDO_HIGHLIGHT_WORD, undoLastEvent);

  useEffect(() => {
    resetLyricHighlighter(lyricsProcessor);
  }, [get(lyricsProcessor, 'lyrics')]);

  useEffect(() => {
    highlightedLyricsTextIn.current = highlightedLyricsText;
    reRenderKonvaVerse();
  }, [highlightedLyricsText]);

  return { setStageRef };
};
