import { useCallback, useRef, useState, useEffect } from 'react';
import { Layer } from 'react-konva';

import { SongInfo } from './SongInfo';
import { PlayComponent } from './PlayComponent';
import { BIG_CANVAS_PROPS } from '../../constans/konva';
import { CounterComponents } from '../../components/Counter/Counter';
import { isEmpty } from 'lodash';

export const ActionsLayer = ({
  counterProps,
  playerActions,
  currentSong,
  selectedSong,
  loading,
  ...props
}) => {
  const [playing, setPlaying] = useState(false);
  const { songId } = selectedSong
  const { play, pause } = playerActions;

  const layer = useRef();

  const handlePlay = useCallback(() => {
    setPlaying(true);
    play();
  }, []);

  useEffect(() => {
    setPlaying(false);
  }, [songId])

  const showActions = useCallback(() => {
    if (!layer.current) return;
    setPlaying(false);
    pause();

    // const [counterNode] = layer.current.find('#counter')
    // if (counterNode) {
    //   counterNode.show()
    // }
  }, []);

  return (
    <Layer id="actions-layer" ref={layer} {...props}>
      <SongInfo currentSong={currentSong} playing={playing} {...props} />
      {!isEmpty(counterProps) && !playing && (
        <CounterComponents
          layer={layer}
          {...counterProps}
          {...props}
          onFinish={handlePlay}
        />
      )}
      {playing && <PlayComponent {...props} showActions={showActions} />}
    </Layer>
  );
};

ActionsLayer.defaultProps = {
  height: BIG_CANVAS_PROPS.height,
  width: BIG_CANVAS_PROPS.width,
  playerActions: {
    play: () => {
      return;
    },
    pause: () => {
      return;
    },
    stop: () => {
      return;
    },
  },
};
