import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useReducer,
} from 'react'
import { isFunction } from 'lodash'
import { _STEPS } from 'actions/index'

export const KaraokeContext = createContext({})

export const useKaraoke = () => {
  return useContext(KaraokeContext)
}

const updater = (state, nextState) =>
  isFunction(nextState)
    ? { ...state, ...nextState(state) }
    : { ...state, ...nextState }

const initState = {
  metadata: {hasVocals: true, spotifyId: ''},
  audio: null,
  lyrics: '',
  instructions: {},
  currentStep: 0,
  stepCompleated: false,
  timelineEvents: {},
}
export const KaraokeProvider = ({ children }) => {
  const [state, setState] = useReducer(updater, initState)

  const setAudio = useCallback((audio) => {
    setState({ audio, stepCompleated: true })
  }, [])
  const setInstructions = useCallback((video) => {
    setState({ video, stepCompleated: true })
  }, [])
  const nextBuildStep = useCallback(() => {
    setState(({ currentStep }) => ({ currentStep: currentStep + 1 }))
  }, [])
  const gobackBuildStep = useCallback(() => {
    setState(({ currentStep }) => ({ currentStep: currentStep - 1 }))
  }, [])
  const resetBuild = useCallback(() => {
    setState(initState)
  }, [])
  const startUploadStep = useCallback((instructions, extMetadata) => {
    setState(({ metadata,...state }) => ({
      ...state,
      metadata: { ...metadata, ...extMetadata },
      instructions,
      currentStep: _STEPS.UPLOADING,
    }))
  }, [])
  const setMetadata = useCallback((newMetadata) => {
    setState(({ metadata }) => ({ metadata: { ...metadata, ...newMetadata } }))
  }, [])
  const setPlaygroud = useCallback(({ audio, metadata, lyrics}) => {
    setState({lyrics ,audio, metadata, currentStep: 1 })
  }, [])

  const value = {
    ...state,
    setAudio,
    setInstructions,
    nextBuildStep,
    gobackBuildStep,
    resetBuild,
    startUploadStep,
    setMetadata,
    setPlaygroud,
  }

  return (
    <KaraokeContext.Provider value={value}>{children}</KaraokeContext.Provider>
  )
}
