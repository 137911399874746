module.exports = {
  FIREBASE: {
    apiKey: "AIzaSyCtZvo88vHsGexbaZeNuSF71Rm16jeopQ4",
    authDomain: "careaoke.firebaseapp.com",
    projectId: "careaoke",
    storageBucket: "careaoke.appspot.com",
    databaseUrl: "https://careaoke-default-rtdb.firebaseio.com/",
    messagingSenderId: "879507235258",
    appId: "1:879507235258:web:5743e442fcd9dcce12fd83"
  }
}