import React from 'react'
import { LyricProcessorProvider } from 'hooks/lyric'
import { KaraokeProvider } from 'hooks/karaoke'
import { TimelineEventsProvider } from 'hooks/TimelineEvents'
import { EventEmitter } from 'utils/EventEmitter'
import { SettingsProvider } from './settings'

// require('../../../public/zip.js')
// console.log(zip)


export const Hooks = ({ children }) => {
  return (
    <EventEmitter>
      <SettingsProvider>
        <TimelineEventsProvider>
          <LyricProcessorProvider>
            <KaraokeProvider>{children}</KaraokeProvider>
          </LyricProcessorProvider>
        </TimelineEventsProvider>
      </SettingsProvider>
    </EventEmitter>
  )
}
