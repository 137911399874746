import { Box, Center } from "@chakra-ui/layout";
// eslint-disable-next-line
export interface FooterProps {
  children?: React.ReactNode,
}

export const Footer = ({children}: FooterProps) => {
  return (
    <Box 
    style={{
    position: 'fixed',
    bottom: -1,
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 15%)'
    }} 
    bg="white" 
    w="100%" 
    p={4} 
    color="white">
      <Center>
        {children}
      </Center>
    </Box>
  )
};

export default Footer;
