import create, { State } from 'zustand';
import { Song } from '@careaoke/types'

// eslint-disable-next-line
export interface UsePlaylistProps {}

interface PlaylistStoreState extends State {
  songs: Song[];
  setSongs: (songs: Song[]) => void;
  addSong: (song: Song) => void
}

export const usePlaylistStore = create<PlaylistStoreState>((set) => ({
  songs: [],
  setSongs: (songs) => set({ songs }),
  addSong: (song) => set((state) => ({ songs: [...state.songs, song] }))
}));

export const usePlaylist = () => {
  const songs = usePlaylistStore(state => state.songs)
  const addSong = usePlaylistStore(state => state.addSong)

  return {
    songs,
    addSong
  }
};

export type UsePlaylistReturn = ReturnType<typeof usePlaylist>;

export default usePlaylist;
