import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalProps,
  Button,
  Center,
  HStack,
  Box
} from "@chakra-ui/react"

interface UIModalProps extends Omit<ModalProps, 'onClose'> {
  cancelText?: string;
  confirmText?: string;
  showButtons?: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
}

const ModalComponent = ({
  cancelText = 'Go Back',
  confirmText = 'Yes',
  showButtons = false,
  isOpen,
  onCancel,
  onConfirm,
  children,
  ...props
}: UIModalProps) => {


  return (
    <Modal isOpen={isOpen} onClose={onCancel} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {children}
        </ModalBody>

        {showButtons && 
        <Center style={{padding: 10}}>
          <HStack 
            spacing="10px" 
            >
              <Button borderRadius="lg" variant="ghost" onClick={onCancel} colorScheme="blue">
                {cancelText}
              </Button>
              <Button borderRadius="lg" colorScheme="blue" mr={3} onClick={onConfirm}>
                {confirmText}
              </Button>
            </HStack>
          </Center>}
      </ModalContent>
    </Modal>
  )
}

export { ModalComponent as Modal }