import { useState, useMemo } from 'react'
import { Event } from '@careaoke/types'
import { firestore, storage } from '../../index'
import { useAuth } from '@careaoke/api'

export const useCreateEvent = () => {
  const { user } = useAuth()
  const [event, setEvent] = useState<Event | any>({})

  const save = async () => {
    const { image, ...rest } = event
    // const newEvent =  await firestore.collection(`event`).add({...rest, currentDonation: 0, user: user?.uid})
    console.log(rest)
    const newEvent =  await firestore.collection(`event`).add({
      ...rest, 
      currentDonation: 0, 
      user: user?.uid, 
      image: 'https://firebasestorage.googleapis.com/v0/b/careaoke.appspot.com/o/image%20(25).png?alt=media&token=f206cbaf-b28a-4b66-b9fd-fac0d1f9f6f4',
      minimumDonation: 0,
      target: 50
    })
    // if (image) {
    //   const ref = storage.ref(`event/${newEvent.id}`)
    //   const uploadedFile = await ref.put(image)
    //   if (uploadedFile.state === "success") {
    //     const url = await ref.getDownloadURL()
    //     await firestore.collection(`event`).doc(newEvent.id).update({image: url})
    //   }
    // }
    return newEvent.id
  }

  // const canSave = useMemo(() => (event?.title && event?.description && event?.startdate && event?.minimumDonation && event?.target), [event])
  const canSave = useMemo(() => (event?.title && event?.description), [event])

  return { 
    save,
    event,
    setEvent,
    canSave
  }
}