import { useState, useEffect, useMemo } from 'react';
import { FcMusic } from 'react-icons/fc';
import { FaChevronLeft, FaRegTimesCircle, FaSearch } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { Song } from '@careaoke/types';

import {
  Box,
  Center,
  Icon,
  IconButton,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SongList,
  Spinner,
  SongItem,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  // Song,
  Modal,
} from '@careaoke/ui';
import {
  useAuth,
  useSearch,
  useEvent,
  useAddSong,
  usePlaylist,
} from '@careaoke/api';
import styles from '../../app.module.scss';
import { SongsByFacet } from './SongsByFacet';

export const Search = ({
  modal, 
  modalOnClose
}: {
  modal?: boolean, 
  modalOnClose?: () => void
}) => {
  const { eventId }: { eventId: string } = useParams();
  const { songs: songsInPlaylist, getIndex } = usePlaylist(eventId);
  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const { event, incrementCurrent } = useEvent(eventId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSong, setSelectedSong] = useState<
    Song | Record<string, unknown>
  >({});
  const [donation, setDonation] = useState(event?.minimumDonation || 0);
  const { user } = useAuth();
  const { songs, loading: songsLoading } = useSearch({ type: 'song', query });

  const { addSong } = useAddSong();

  const buyClicked = (song: Song) => {
    if (getIndex(song.songId) >= 0) {
      toast({
        title: `${song?.name} has already been chosen, please choose a different song`,
        status: 'error',
        duration: 2000,
      });
      return;
    }
    onOpen();
    setSelectedSong(song);
  };

  useEffect(() => {
    setDonation(event?.minimumDonation || 0);
  }, [event?.minimumDonation]);

  const onConfirm = () => {
    setLoading(true);
    addSong(
      {
        ...selectedSong,
        user: user?.uid,
        event: eventId,
        index: songsInPlaylist.length,
      },
      eventId
    );
    incrementCurrent(donation);
    setSelectedSong({});
    setDonation(event?.minimumDonation || 0);
    setTimeout(() => {
      toast({
        title: `${selectedSong?.name} succesfully added to playlist`,
        status: 'success',
      });
      setTimeout(()=> {
        modal ? (modalOnClose && modalOnClose()) : history.goBack()
      }, 250)
    }, 250);
  };

  const showFacets = useMemo(() => {
    if (songs.length === 0 && query !== '') return false;
    if (songs.length === 0) return true;
    if (query === '') return true;
    return false;
  }, [query, songs.length]);

  return (
    <Box className={modal ? '' : styles.layout}>
      <Box pt="5" w="100%">
        {!modal && <IconButton
          style={{ position: 'absolute' }}
          onClick={history.goBack}
          aria-label="back"
          size="sm"
          icon={<Icon as={FaChevronLeft} />}
        />}
        <Center>
          <Icon boxSize="2em" as={FcMusic} />
        </Center>
      </Box>
      <Box pt="5" textAlign="center">
        <Text fontSize="2em" fontWeight="bold">
          Search Songs
        </Text>
        <Text>Search the library of available songs to add to your queue.</Text>
      </Box>
      <Box pt="5">
        <Flex>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} />}
            />
            <Input
              placeholder="Search Songs"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              value={query}
            />
            {query !== '' && (
              <InputRightElement
                children={
                  <Icon as={FaRegTimesCircle} onClick={() => setQuery('')} />
                }
              />
            )}
          </InputGroup>
        </Flex>
      </Box>
      {showFacets ? (
        <SongsByFacet onSongClicked={buyClicked} />
      ) : (
        <Box pt="5">
          <Text fontWeight="bold" style={{ marginBottom: 5 }}>
            Results ({songsLoading ? '...' : songs.length})
          </Text>
          <SongList
            loading={songsLoading}
            songs={songs}
            renderItem={(song: Song, i: number) => {
              return (
                <SongItem
                  key={i}
                  song={song}
                  isFromSearch
                  actions={{ buy: buyClicked }}
                />
              );
            }}
          />
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={onConfirm}
        cancelText={'Go Back'}
        confirmText={'Confirm'}
        showButtons={!loading}
        size="xs"
        isCentered
      >
        {loading ? (
          <Center style={{ padding: 10 }}>
            <Spinner size="xl" />
          </Center>
        ) : (
          <Box pt="5" pb="5">
            <Box>
              <Text pb="1">Optional: Tip the Karaoke Creator - <Text fontWeight="bold">{selectedSong.brandName || 'Careaoke'} </Text></Text>
            </Box>
            <NumberInput
              defaultValue={donation}
              onChange={(valueString) => setDonation(parseInt(valueString))}
              min={event?.minimumDonation}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
        )}
      </Modal>
    </Box>
  );
};
