import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
} from 'react'
import { isFunction } from 'lodash'
import { HIGHLIGHTED_TEXT_PROPS, LYRIC_TEXT_PROPS } from 'constans/konva'

export const SettingsContext = createContext({})

export const useSettings = () => {
  return useContext(SettingsContext)
}

const updater = (state, nextState) =>
  isFunction(nextState)
    ? { ...state, ...nextState(state) }
    : { ...state, ...nextState }

const initState = {
  lyricsText: LYRIC_TEXT_PROPS,
  highlightedLyricsText: HIGHLIGHTED_TEXT_PROPS,
}
export const SettingsProvider = ({ children }) => {
  const [state, setState] = useReducer(updater, initState)

  const setFontSize = useCallback((fontSize) => {
    setState(({ lyricsText, highlightedLyricsText }) => ({
      lyricsText: { ...lyricsText, fontSize },
      highlightedLyricsText: { ...highlightedLyricsText, fontSize },
    }))
  }, [])

  const value = {
    ...state,
    setFontSize,
  }

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}
