import { floor } from 'lodash'

export const secondsToMinutes = (s) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s.toFixed(0)
}

export const getAspectRatio = (value) => {
  const t = value
  const def = { width: 480, height: 270 }
  if (!t) return def
  if (t % 1 == 0) {
    var height = (parseInt(value) / 16) * 9
    return { width: value, height }
  } else return def
}

export const parseTimeKey = (value) => floor(value, 5) //`${value}`.match(/[\d]+.[\d]{2}/)[0]

export const exportFile = (data) => {
  try {
    const text = JSON.stringify(data)
    const blob = new Blob([text], { type: 'application/json' })
    return blob
  } catch (error) {
    return
  }
}