import { getAspectRatio } from 'utils/convert'
export const LYRIC_TEXT_LINEHEIGHT = 4
export const LYRIC_TEXT_PROPS = {
  fontSize: window.innerHeight / 12,
  // verticalAlign: 'middle',
  // align: 'center',
  fill: 'white',
  color: 'white',
  // lineHeight: 4,
  // padding: 8,
  fontStyle: 'bold',
}
export const HIGHLIGHTED_TEXT_PROPS = {
  ...LYRIC_TEXT_PROPS,
  align: 'left',
  fill: '#FFD266',
};

export const SMALL_CANVAS_ASPECT = 2 / 3
export const BIG_CANVAS_PROPS = getAspectRatio(window.innerWidth)
export const SMALL_CANVAS_PROPS = getAspectRatio(
  BIG_CANVAS_PROPS.width * SMALL_CANVAS_ASPECT
)
export const CANVAS_PADDING = 60

export const COUNTER_TEXT_PROPS = {
  fontSize: 60,
  verticalAlign: 'middle',
  align: 'center',
  fill: 'white',
  color: 'white',
}
export const COUNTER_SWITCH_COLORS = [
  '#566270',
  '#4ecdc4',
  '#c7f465',
  '#ff6b6b',
  '#c54d57',
]
