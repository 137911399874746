import React, {
  useCallback,
  useMemo,
  useRef,
  createContext,
  useContext,
  useEffect,
} from 'react'

export const EventContext = createContext({})
const reduceFn = (state, action) => {
  const { type, event, payload } = action
  switch (type) {
    case 'subscribe': {
      const { callback } = action
      if (event in state) {
        if (state[event].includes(callback)) {
          return state
        }
        return { ...state, [event]: [...state[event], callback] }
      }
      return { ...state, [event]: [callback] }
    }

    case 'unsubscribe': {
      const { callback } = action
      if (event in state && state[event].includes(callback)) {
        return {
          ...state,
          [event]: [...state[event].filter((cb) => cb !== callback)],
        }
      }
      return state
    }

    case 'event': {
      if (event in state) {
        state[event].forEach((cb) => cb(payload))
      }
      return state
    }

    default:
      return state
  }
}
export const EventEmitter = ({ children }) => {
  const stateIn = useRef({})

  const dispatch = useCallback((action) => {
    stateIn.current = reduceFn(stateIn.current, action)
  })

  const subscribe = useCallback((event, callback) => {
    dispatch({ type: 'subscribe', event, callback })
  }, [])

  const unsubscribe = useCallback((event, callback) => {
    dispatch({ type: 'unsubscribe', event, callback })
  }, [])

  const dispatchEvent = useCallback((event, payload) => {
    dispatch({ type: 'event', event, payload })
    // if (event in subscribers) {
    //   subscribers[event].forEach((cb) => cb(payload))
    // }
  }, [])

  const eventPack = useMemo(() => ({ subscribe, unsubscribe, dispatchEvent }), [
    subscribe,
    unsubscribe,
    dispatchEvent,
  ])

  return (
    <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
  )
}

export const useEventListener = (event, handleEvent) => {
  const context = useContext(EventContext)

  useEffect(() => {
    context.subscribe(event, handleEvent)
    return () => {
      context.unsubscribe(event, handleEvent)
    }
  }, [event, handleEvent])
}

export const useEventDispatch = (event, initPayload) => {
  const context = useContext(EventContext)

  const dispatchEvent = useCallback(
    (payload) => {
      context.dispatchEvent(event, payload)
    },
    [event]
  )

  // useEffect(() => {
  //   if (event && initPayload) dispatchEvent(initPayload)
  // }, [event, initPayload])

  return { dispatchEvent }
}

export const _EVENTS = {
  TIMELINE_EVENT: 'DISPATCH_TIMELINE_EVENT',
  VERSE_CHANGE: 'DISPATCH_VERSE_CHANGE',
  RESTART_KARAOKE: 'DISPATCH_RESTART_KARAOKE',
  NEXT_VERSE: 'DISPATCH_NEXT_VERSE',
  PREV_VERSE: 'DISPATCH_PREV_VERSE',
  SYNC_TIMELINE_EVENT: 'DISPATCH_SYNC_TIMELINE_EVENT',
  HIGHLIGHT_NEXT_WORD: 'DISPATCH_HIGHLIGHT_NEXT_WORD',
  UNDO_HIGHLIGHT_WORD: 'DISPATCH_UNDO_HIGHLIGHT_WORD',
  VERSE_HIGHTLIGHTED: 'DISPATCH_VERSE_HIGHTLIGHTED',
}
