import { Flex, Spacer, HStack } from '@chakra-ui/react'
import { Progress, ProgressProps } from '@careaoke/ui'

// eslint-disable-next-line
export interface EventProgressProps extends ProgressProps {
  fontSize?: number,
  target: number,
  current: number,
  percentage: number,
}

export const EventProgress = ({fontSize = 14, target, current, percentage, ...props}: EventProgressProps) => {

  return (
    <>
      <Progress
      style={{marginBottom: 15}}
      max={target}
      value={current}
      {...props}
      />
      <Flex >
        <HStack 
        fontSize={fontSize}
        spacing="2px" 
        >
          <div style={{opacity: 0.5}}>Tipped:</div><div style={{fontWeight: 'bold'}}>${current}</div><div style={{opacity: 0.5}}>({percentage}%)</div>
        </HStack>
        <Spacer/>
        <HStack 
        fontSize={fontSize}
        spacing="2px" 
        >
          <div style={{opacity: 0.5}}>Goal:</div><div style={{fontWeight: 'bold'}}>${target}</div>
        </HStack>
      </Flex>
    </>
  )
};

export default EventProgress;
