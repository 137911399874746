import { Avatar, AvatarBadge, Button, forwardRef } from '@chakra-ui/react'
// eslint-disable-next-line
export interface MyAvatarProps {
  isMine?: boolean,
  alreadyPlayed?: boolean,
  user?: string
}

export const MyAvatar = forwardRef<MyAvatarProps, 'div'>((
  {
    user, 
    isMine, 
    onClick, 
    alreadyPlayed
  }, ref) => {
  return (
    <div style={{cursor: 'pointer'}} onClick={onClick} ref={ref}>
      <Avatar 
        opacity={alreadyPlayed ? 0.5 : 1} 
        size="sm" 
        name={user} 
        src={`https://avatars.dicebear.com/api/croodles-neutral/${user}.svg`}
      >
        {isMine && <AvatarBadge boxSize="1em" bg="blue.500" />}
      </Avatar>
    </div>
  )
});

export default MyAvatar;
