import create from 'zustand';
import { persist } from 'zustand/middleware';

import { Song } from '@careaoke/types';
import { FacetDict } from './types';

interface SearchStore {
  facets: {
    [facetName: string]: {
      data?: FacetDict;
      timestamp: number;
    };
  };
  setFacets: (key: string, facets: FacetDict) => void;

  facetSongs: {
    [facetName: string]: {
      [label: string]: {
        songs: Song[];
        timestamp: number;
      };
    };
  };
  setFacetSongs: (facetName: string, label: string, songs: Song[]) => void;
}

export const useSearchStore = create<SearchStore>(
  persist(
    (set) => ({
      facets: {},
      setFacets: (key, data) =>
        set({ facets: { [key]: { data, timestamp: Date.now() } } }),

      facetSongs: {},
      setFacetSongs: (facetName, label, songs) =>
        set((state) => ({
          facetSongs: {
            [facetName]: {
              ...state.facetSongs[facetName],
              [label]: { songs, timestamp: Date.now() },
            },
          },
        })),
    }),
    { name: 'search-store' }
  )
);
