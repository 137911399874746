import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  useAuth,
  useEvent,
  usePlaylist,
  useNowPlaying,
} from '@careaoke/api';
import { Song } from '@careaoke/types';
import {
  Button,
  Footer,
  Box,
  SongList,
  Divider,
  Image,
  SongItem,
  Text,
  Center,
  Spinner,
  EventInfo,
  EventProgress,
  EventStatus,
} from '@careaoke/ui';
import styles from '../../app.module.scss';

export const Event = () => {
  const { eventId }: { eventId: string } = useParams();
  const history = useHistory()

  const { user } = useAuth();
  const {
    songs,
    loading: playlistLoading,
    getIndex
  } = usePlaylist(eventId);
  const { id, current } = useNowPlaying(eventId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { event, loading, percentage } = useEvent(eventId);

  useEffect(() => {
    const index = getIndex(id);
    setCurrentIndex(index);
  }, [id, songs]);

  if (loading)
    return (
      <Center style={{ padding: 10 }}>
        <Spinner size="xl" />
      </Center>
    );
  if (!event) {
    history.push('/')
    return <div></div>
  }

  return (
    <>
      {event?.image && (
        <Image htmlWidth="100%" htmlHeight="100%" src={event.image} />
      )}
      <Box className={styles.layout}>
        <EventInfo event={event} user={user?.uid} />
        <div style={{ marginTop: 10 }}>
          <EventProgress
            target={event?.target}
            current={event?.currentDonation}
            percentage={percentage}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <EventStatus
            hasStarted={moment(event.startdate).unix() < moment().unix()}
          />
        </div>
        <Box bg="white" w="100%" p={4}>
          <Divider />
        </Box>
        <Text fontWeight="bold" style={{ marginBottom: 5 }}>
          Playlist ({songs.length})
        </Text>
        <SongList
          songs={songs}
          loading={playlistLoading}
          renderItem={(song: Song, i: number) => (
            <SongItem
              key={i}
              song={song}
              current={current}
              isPlaying={id === song.songId}
              isMine={song.user === user?.uid}
              alreadyPlayed={i < currentIndex}
            />
          )}
        />
        <Box h="72px" />
      </Box>
      <Footer>
        <Link to={`/search/${eventId}`}>
          <Button colorScheme="blue">
            <Text color="white" fontSize="0.7em">
              Search Songs & Sing
            </Text>
          </Button>
        </Link>
      </Footer>
    </>
  );
};
