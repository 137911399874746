import { Link } from 'react-router-dom'
import { Box, Center, Flex, Text, Button } from '@careaoke/ui'
import { Container } from '@chakra-ui/react'
import { ImageWrapper } from './ImageWrapper'
import * as image from './assets/curtain.png'
import * as logo from './assets/diveBar_marquee.png';
import * as step1 from './assets/step1.png'
import * as step2 from './assets/step2.png'
import * as step3 from './assets/step3.png'
import * as step4 from './assets/step4.png'

const Step = ({children}: {children: React.ReactNode}) => {
  return (
    <Container 
      maxW='xs' 
      centerContent 
      justifyContent="center"
      borderWidth="1px"
      padding='4' 
      borderRadius='lg'
      background="gray.900"
      textAlign="center"
      color="white"
    >
      {children}
    </Container>
  )
}

export const Home = () => {

  return (
    <Box height="100vh" backgroundImage={image}>
      <Box flex="1" >
        <Center>
          <Flex flexDirection="column">
            <ImageWrapper width="100%" src={logo}/>
            <Center>
              <Box 
                position="absolute"
                borderWidth='1px' 
                backgroundColor="gray.900"
                borderRadius='lg' 
                mt="-50px"
                py="2"
                px="5"
              >
                <Text color="white">
                  Curated Karaoke
                </Text>
              </Box>
            </Center>
          </Flex>
        </Center>
        <Box pt="50px">
          <Flex justifyContent="space-between">
            <Step>
              <ImageWrapper width="150px" src={step1}/>
              <Text>
                Get The Party Started
              </Text>
              <Text 
                fontSize="12px"
              >
                Start a new karaoke party with an empty song queue.
              </Text>
            </Step>
            <Step>
              <ImageWrapper width="150px" src={step2}/>
              <Text>
                Browse The Book
              </Text>
              <Text 
                fontSize="12px"
              >
                Search the library of available songs to add to your queue. 
              </Text>
            </Step>
            <Step>
              <ImageWrapper width="150px" src={step3}/>
              <Text>
                Sing!
              </Text>
              <Text 
                fontSize="12px"
              >
                Songs will be loaded into party playlist in the order they were queued. Follow the rotation or click a desired song in the queue to jump around. 
              </Text>
            </Step>
            <Step>
              <ImageWrapper width="150px" src={step4}/>
              <Text>
                Invite Friends
              </Text>
              <Text 
                fontSize="12px"
              >
                Friends make it more fun. Use the invite friends button on the player to invite others to the party.
              </Text>
            </Step>
          </Flex>
        </Box>
      </Box>
      <Box 
        position="absolute"
        bottom="0"
        w="100%" 
        p={4} 
      >
        <Center>
          <Link to={`/createEvent`}>
            <Button size="lg" colorScheme="blue">
              <Text color="white" fontSize="0.7em">
                Let's do this!
              </Text>
            </Button>
          </Link>
        </Center>
      </Box>
    </Box>
  )
}