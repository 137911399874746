import { Box, Center, Text } from '@chakra-ui/react'

// eslint-disable-next-line
export interface EventStatusProps {
  hasStarted: boolean
}

export const EventStatus = ({hasStarted}: EventStatusProps) => {
  if (hasStarted) {
      return (
        <Box bg="#48BB78" p={3} width="100%" borderRadius="lg">
          <Center><Text fontSize="0.8em" fontWeight="bold" color="white">Event Started!</Text></Center>
        </Box>
      )
    } else {
      return (
        <Box borderWidth="1px" borderRadius="lg" p={3} width="100%">
          <Center><Text fontSize="0.8em" fontWeight="bold">Waiting for event to start...</Text></Center>
        </Box>
      )
  }
};

export default EventStatus;
