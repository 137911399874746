import React, {
  createContext,
  useContext,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  find,
  orderBy,
  findLast,
  reject,
  dropRight,
  omit,
  map,
  forEach,
  get,
  ceil,
} from 'lodash';
import {
  useEventDispatch,
  useEventListener,
  _EVENTS,
} from 'utils/EventEmitter';
import { parseTimeKey } from 'utils/convert';
import { useKeyboardSyncker } from './keyboard';
import { usePlayer, usePlaylistStore, useSelectedSong } from '@careaoke/api';

export const TIMELINE_ACTIONS = {
  change_verse: 'CHANGE_VERSE',
  next_verse: 'NEXT_VERSE',
  restart_karaoke: 'RESTART_KARAOKE',
  highlight_word: 'SET_HIGHLIGHT_WORD',
  change_verse_Hightlighted: 'CHANGE_VERSE_HIGHTLIGHTED',
  highlight_next_word: 'HIGHLIGHT_NEXT_WORD',
};
export const TimelineEventsContext = createContext({});

export const useTimelineEvents = () => {
  return useContext(TimelineEventsContext);
};

const sortTimelineEvent = (events) =>
  orderBy(map(events, (x, time) => Number(time)));

export const TimelineEventsProvider = ({ children }) => {
  const sortedTimes = useRef([]);
  const timelineEvents = useRef([]);

  const setTimelineAction = useCallback((time, event) => {
    const pTime = parseTimeKey(time);
    // Always sorted
    sortedTimes.current = orderBy([...sortedTimes.current, pTime]);

    const state = timelineEvents.current;
    if (state[pTime]) {
      const alreadyIn = reject(state[pTime], { type: event.type });
      timelineEvents.current = { ...state, [pTime]: [...alreadyIn, event] };
    } else {
      timelineEvents.current = { ...state, [pTime]: [event] };
    }

    console.log('Added Timeline event:', { [pTime]: event });
  }, []);

  const registerTimeEvent = useCallback(
    ({
      time,
      restartKaraoke,
      verse,
      nextVerse,
      highlightWord,
      verseHighlighted,
      highlightNextWord,
    }) => {
      if (!isFinite(time)) return;

      if (highlightNextWord) {
        setTimelineAction(time, {
          type: TIMELINE_ACTIONS.highlight_next_word,
          highlightNextWord,
        });
      }
      if (verseHighlighted) {
        setTimelineAction(time, {
          type: TIMELINE_ACTIONS.change_verse_Hightlighted,
          verseHighlighted,
        });
      }
      if (highlightWord) {
        setTimelineAction(time, {
          type: TIMELINE_ACTIONS.highlight_word,
          word: highlightWord,
          highlightWord,
        });
      }
      if (nextVerse) {
        setTimelineAction(time, {
          type: TIMELINE_ACTIONS.next_verse,
          nextVerse,
        });
      }
      if (restartKaraoke)
        setTimelineAction(time, {
          type: TIMELINE_ACTIONS.restart_karaoke,
          restartKaraoke,
        });
      if (verse)
        setTimelineAction(time, { type: TIMELINE_ACTIONS.change_verse, verse });
    },
    []
  );

  const findNextEvents = useCallback((time) => {
    if (!isFinite(time)) return {};

    const pTime = find(sortedTimes.current, (pointTime) => pointTime >= time);
    if (!isFinite(pTime)) return {};

    return {
      time,
      timeLeft: pTime - time,
      events: timelineEvents.current[parseTimeKey(pTime)],
      id: pTime,
    };
  }, []);
  const undoLastEvent = useCallback(() => {
    const pTime = findLast(sortedTimes.current);
    if (!isFinite(pTime)) return;

    sortedTimes.current = dropRight(sortedTimes.current);

    const state = timelineEvents.current;
    timelineEvents.current = omit(state, [pTime]);
  }, [timelineEvents]);

  const resetTimelineEvents = useCallback((state = {}) => {
    sortedTimes.current = sortTimelineEvent(state);
    timelineEvents.current = { ...state };
  }, []);

  const getTimelineEvents = useCallback(() => timelineEvents.current, []);

  const value = {
    timelineEvents,
    getTimelineEvents,
    setTimelineAction,
    findNextEvents,
    resetTimelineEvents,
    undoLastEvent,
  };

  useEventListener(_EVENTS.TIMELINE_EVENT, registerTimeEvent);

  return (
    <TimelineEventsContext.Provider value={value}>
      {children}
    </TimelineEventsContext.Provider>
  );
};

const useTimelinePlayer = () => {
  const long = 180000;
};

export const useEventsPlayer = ({ audioRef, onEvent, onStop }) => {
  const { findNextEvents } = useTimelineEvents();
  const selectedSong = usePlaylistStore(state => state.selectedSong)
  const {songId} = selectedSong
  const [newTime, setNewTime] = useState(0)

  useEffect(() => {
    // avoid callback nonsense where 'selectedSong' id is old id
    onEvent({time: newTime})
  }, [newTime])

  const nonRepeatable = useRef();
  const idTimeout = useRef();

  const { dispatchEvent: lighlightNextWord } = useEventDispatch(
    _EVENTS.HIGHLIGHT_NEXT_WORD
  );
  const { dispatchEvent: changeVerse } = useEventDispatch(_EVENTS.VERSE_CHANGE);
  const { dispatchEvent: restartKaraoke } = useEventDispatch(
    _EVENTS.RESTART_KARAOKE
  );
  const { dispatchEvent: triggerNextVerse } = useEventDispatch(
    _EVENTS.NEXT_VERSE
  );

  const playNextEvent = useCallback(() => {
    if (!audioRef.current) return;

    const { currentTime: audioTime } = audioRef.current;

    // Pick the next event about to happen
    const { timeLeft, events, time, id } = findNextEvents(audioTime);
    if (nonRepeatable.current == id)
      return setTimeout(() => playNextEvent(), 20);
    nonRepeatable.current = id;

    if (!isFinite(timeLeft)) {
      console.log('no time left');
      return;
    }
    const timer = ceil(timeLeft * 1000, 0);

    idTimeout.current = setTimeout(() => {
      forEach(events, ({ type, verse, nextVerse, verseHighlighted }, key) => {
        // console.log(
        //   `Timeout PlayEvent: ${type} time: ${time} timer: ${timer} eventTime: ${key}`
        // )
        // onOnEvent && onOnEvent({ time });
        setNewTime(time)
        switch (type) {
          case TIMELINE_ACTIONS.next_verse:
            return triggerNextVerse(nextVerse);

          case TIMELINE_ACTIONS.highlight_next_word:
            return lighlightNextWord();

          case TIMELINE_ACTIONS.restart_karaoke:
            return restartKaraoke(verse);

          case TIMELINE_ACTIONS.change_verse:
            return changeVerse(verse);
        }
      });
      playNextEvent();
    }, timer);
  }, [
    audioRef,
    findNextEvents,
    changeVerse,
    onEvent,
    restartKaraoke,
    triggerNextVerse,
    lighlightNextWord,
    selectedSong
  ]);

  const stopPlayingEvents = useCallback(() => {
    clearTimeout(idTimeout.current);
    onStop && onStop();
    // togglePlaying({eventId, playing: false})
  }, [onStop, songId]);

  const play = useCallback(() => {
    const src = get(audioRef, 'current.src');
    if (!src) {
      console.log('Cant play', src);
      return;
    }
    playNextEvent();
    audioRef.current.removeEventListener('ended', stopPlayingEvents);
    audioRef.current.addEventListener('ended', stopPlayingEvents);
    audioRef.current.play();
  }, [audioRef, playNextEvent, stopPlayingEvents, songId]);

  const pause = useCallback(() => {
    const src = get(audioRef, 'current.src');
    if (!src) {
      console.log('Cant pause', src);
      return;
    }
    stopPlayingEvents();
    nonRepeatable.current = null;
    audioRef.current.pause();
  }, [audioRef, stopPlayingEvents, songId]);

  // const resetTimelinePlayer = useCallback(() => {
  //   console.log('resetting')
  //   stopPlayingEvents()
  //   restartKaraoke()
  // }, [stopPlayingEvents, restartKaraoke])

  return {
    playNextEvent,
    stopPlayingEvents,
    restartKaraoke,
    play,
    pause,
  };
};

export const useEventRecorder = ({ audioRef }) => {
  useKeyboardSyncker();

  const { undoLastEvent, resetTimelineEvents } = useTimelineEvents();
  const { dispatchEvent: addTimelineEvent } = useEventDispatch(
    _EVENTS.TIMELINE_EVENT
  );

  const handleVerseChange = useCallback(
    (verse) => {
      if (!audioRef.current) return;
      const time = audioRef.current.currentTime;
      addTimelineEvent({ time, verse });
    },
    [audioRef]
  );
  const handleNextVerse = useCallback(
    (nextVerse) => {
      if (!audioRef.current) return;
      const time = audioRef.current.currentTime;
      addTimelineEvent({ time, nextVerse: true });
    },
    [audioRef]
  );

  const handleHighlightNextWord = useCallback(() => {
    if (!audioRef.current) return;
    const time = audioRef.current.currentTime;
    addTimelineEvent({ time, highlightNextWord: true });
  }, [audioRef]);

  useEventListener(_EVENTS.VERSE_CHANGE, handleVerseChange);
  useEventListener(_EVENTS.NEXT_VERSE, handleNextVerse);
  useEventListener(_EVENTS.HIGHLIGHT_NEXT_WORD, handleHighlightNextWord);
  useEventListener(_EVENTS.UNDO_HIGHLIGHT_WORD, undoLastEvent);

  // Every time we try record events in the time line, prev data must be wipeout
  useEffect(() => {
    resetTimelineEvents();
  }, []);
};
