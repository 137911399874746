import { useEffect, useState } from 'react';
import { Image, Rect } from 'react-konva';

import * as bg from './kr-background-02-darker-02.jpg';

export const Background = ({ width, height }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    const im = new window.Image();
    im.src = bg;
    im.addEventListener('load', () => {
      setImage(im);
    });
  }, []);
  return (
    <Rect
      width={width}
      height={height}
      fillRadialGradientStartPoint={{ x: width / 2, y: 200 }}
      fillRadialGradientStartRadius={0}
      fillRadialGradientEndPoint={{ x: 0, y: 0 }}
      fillRadialGradientEndRadius={width * 2}
      fillRadialGradientColorStops={[0, '#0167B1', 0.5, '#000000']}
    />
  );
  // return <Image image={image} width={width} height={height} />;
};
