import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Song } from '@careaoke/types'
import { useDisclosure, ModalCloseButton } from '@chakra-ui/react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  SongList,
  SongItem,
  Footer,
  Box,
  Text
} from "@careaoke/ui"
import { 
  usePlaylist,
  usePlaylistStore,
  useNowPlaying, 
} from '@careaoke/api'
import { Search } from '../Search'
import { Modal, Button } from '@careaoke/ui'

// eslint-disable-next-line
export interface PlayerDrawerProps {
  isOpen: boolean, 
  onClose: () => void
}

export const PlayerDrawer = ({isOpen, onClose}: PlayerDrawerProps) => {
  const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose } = useDisclosure()
  const setSelectedSong = usePlaylistStore(state => state.setSelectedSong)
  const { eventId }: {eventId: string} = useParams()
  const {
    songs,
    loading: playlistLoading,
    getIndex,
    bumpSong,
  } = usePlaylist(eventId);
  const { id, current } = useNowPlaying(eventId);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const index = getIndex(id);
    setCurrentIndex(index);
  }, [id, songs]);

  const bump = useCallback(
    (song: Song) => {
      bumpSong(song, id);
    },
    [songs, id]
  );

  const play = (song: Song) => {
    setSelectedSong(song)
    onClose()
  }

  return (
    <div style={{padding: 10}}>
      <Drawer
        isOpen={(!playlistLoading && !songs?.length) || isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay/>
        <DrawerContent>
          <div style={{padding: 10, overflow: 'scroll' }}>
            <SongList
              songs={songs}
              loading={false}
              renderItem={(song: Song, i: number) => {
              return (
                <div key={i}>
                  <SongItem 
                    key={i}
                    song={song} 
                    actions={{ play, bump }}
                    isPlaying={id === song.songId}
                    alreadyPlayed={i < currentIndex}
                    isPlayer
                  />
                </div>
              )
            }}
            />
            <Box h="72px" />
            <Footer>
              <Button onClick={modalOnOpen} colorScheme="blue">
                <Text color="white" fontSize="0.7em">
                  Search Songs & Sing
                </Text>
              </Button>
            </Footer>
        </div>
        </DrawerContent>
        <Modal
        isOpen={(!playlistLoading && !songs?.length) || modalIsOpen}
        onCancel={modalOnClose}
        scrollBehavior="inside"
        size="full"
        isCentered
      >
        <ModalCloseButton/>
        <Search modal modalOnClose={modalOnClose}/>
      </Modal>
      </Drawer>
    </div>
  )
}