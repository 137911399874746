import { useRef, useEffect, useState } from "react";
import YouTube from 'react-youtube';

import { Flex } from '@careaoke/ui'
import { Song } from '@careaoke/types';

import './styles.scss';

interface YoutubePlayerProps {
  selectedSong: Song;
  nextSong: Song;
  onPlay: () => void;
  onPause: () => void;
  setSongFinished: (isFinished: boolean) => void;
  onSongLoaded: () => void;
  onUpdateTime: (time: number) => void;
  renderToolbar: () => React.ReactElement;
  onMusicStop: () => void;
}

export const YoutubePlayer = ({
  selectedSong,
  renderToolbar,
  onMusicStop,
  onUpdateTime,
  setSongFinished,
  onPause,
}: YoutubePlayerProps) => {
  const timer: { current: ReturnType<typeof setTimeout> | null } = useRef(null);

  const playerRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false)
  const { fileUrl } = selectedSong
  
  const getTime = () => {
    return playerRef?.current?.internalPlayer?.getCurrentTime()
  }

  const onVideoPlay = async () => {
    const time = await getTime()
    setIsPlaying(true)
    onUpdateTime(time)
  }

  const onVideoPause = () => {
    setIsPlaying(false)
    onPause()
  }

  const onVideoEnd = () => {
    setIsPlaying(false)
    onMusicStop()
    setSongFinished(true)
  }

  useEffect(() => {
    if (isPlaying) {
      timer.current = setInterval(async () => {
        const time = await getTime()
        onUpdateTime(time)
      }, 2000);
    } else {
      clearInterval(
        timer.current 
        || setInterval(() => {
          console.log();
        }, 0)
      );
    }
  }, [isPlaying])

  return (
    <Flex className="vhMinusTwenty">
      <YouTube
        ref={playerRef} 
        opts={{
          playerVars: {
            controls: 0,
          }
        }}
        videoId={fileUrl}
        containerClassName={"youtubeContainer"}
        onPlay={onVideoPlay}
        onPause={onVideoPause}
        onEnd={onVideoEnd}
      />
      <Flex 
        position="absolute" 
        right="15px" 
        bottom="0" 
      >
        {renderToolbar()}
      </Flex>
    </Flex>
  )
}
