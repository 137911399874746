import { 
  Box, 
  SongProgress, 
  SongTitle, 
  Button, 
  AvatarWithMenu 
} from '@careaoke/ui';
import { Song } from '@careaoke/types';

// eslint-disable-next-line
export interface SongItemProps {
  isFromSearch?: boolean;
  isPlaying?: boolean;
  isPlayer?: boolean;
  isMine?: boolean;
  alreadyPlayed?: boolean;
  current?: number;
  song: Song;
  user?: string;
  actions?: {
    play?: (song: Song) => void;
    buy?: (song: Song) => void;
    bump?: (song: Song) => void;
  };
}

export const SongItem = ({
  isFromSearch = false,
  isPlaying = false,
  current = 0,
  isMine = false,
  actions = {},
  alreadyPlayed,
  song,
  isPlayer
}: SongItemProps) => {
  return (
    <Box pt={isPlaying ? 2 : 1} pb={isPlaying ? 5 : 1}>
      <SongTitle
        song={song}
        isPlaying={isPlaying}
        alreadyPlayed={alreadyPlayed}
      >
        {isFromSearch && (
          <Button
            onClick={() => {
              actions.buy && actions.buy(song);
            }}
            colorScheme="blue"
            size="sm"
          >
            Sing
          </Button>
        )}
        {song.user && 
        <AvatarWithMenu 
          alreadyPlayed={alreadyPlayed} 
          isMine={isMine}
          song={song}
          actions={actions}
          isPlaying={isPlaying}
          isPlayer={isPlayer}
        />}
      </SongTitle>
      {(isPlaying && !isPlayer) && 
      <SongProgress song={song} current={current} />}
    </Box>
  );
};

export default SongItem;
