import { useState, useEffect, useRef } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useObject } from 'react-firebase-hooks/database';
import create, { State } from 'zustand';
import { persist } from 'zustand/middleware';
import { Song } from '@careaoke/types';
import { firestore, database } from '../../index';

// eslint-disable-next-line
export interface UsePlaylistProps {}

interface PlaylistStoreState extends State {
  selectedSong: Song | Record<string, unknown>;
  setSelectedSong: (selectedSong: Song) => void;
}

export const usePlaylistStore = create<PlaylistStoreState>((set) => ({
  selectedSong: {},
  setSelectedSong: (selectedSong) => set({ selectedSong }),
}));

export const usePlaylist = (eventId: string) => {
  const setSelectedSong = usePlaylistStore((state) => state.setSelectedSong);

  const [songs, loading, error] = useCollectionData<Song>(
    firestore
      .collection(`playlistSongs/${eventId}/songs`)
      .orderBy('index', 'asc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
      idField: "firebaseId"
    }
  );

  if (error) console.log(error)

  const getIndex = (id?: number | unknown) => {
    return songs?.findIndex((song) => id === song.songId) || 0;
  };

  const playNextSong = (currentIndex: number) => {
    if (currentIndex >= 0) {
      const newIndex = currentIndex + 1;
      if (songs && songs[newIndex]) setSelectedSong(songs[newIndex]);
    } else {
      console.log('no current index provided');
      return;
    }
  };

  const playThisSong = (id: number) => {
    let index = getIndex(id);
    index = index >= 0 ? index : 0;
    if (index >= 0 && songs && songs[index]) {
      setSelectedSong(songs[index]);
    }
  };

  const bumpSong = (song: Song, current: number) => {
    console.log('bumping song', song, current);
    const indexToBump = song.index || 0
    const nextIndex = getIndex(current) + 1
    const _songs = [...songs || []]

    const songToBump = _songs?.splice(indexToBump, 1)
    const songsToUpdate = _songs?.slice(nextIndex, indexToBump)
    const newSongs = [...songToBump || [], ...songsToUpdate || []]

    newSongs.forEach((song, i) => {
      firestore.collection(`playlistSongs/${eventId}/songs`)
      .doc(song.firebaseId).update({index: i + nextIndex})
    })
    
  };

  return {
    songs: songs || [],
    loading,
    playNextSong,
    playThisSong,
    getIndex,
    bumpSong,
  };
};

export const useAddSong = () => {
  const addSong = (song: Song | Record<string, unknown>, eventId: string) => {
    if (song?.name)
      firestore
        .collection(`playlistSongs/${eventId}/songs`)
        .add({ ...song, createdAt: Date.now() });
  };
  return {
    addSong,
  };
};

export const useNowPlaying = (eventId: string) => {
  const timer: { current: ReturnType<typeof setTimeout> | null } = useRef(null);
  const pausedRef = useRef(false);
  const [current, setCurrent] = useState(0);
  const [snapshot] = useObject(database.ref(`event/${eventId}`));
  const { id, time, playing } = snapshot?.val() || {};

  useEffect(() => {
    if (!playing) return;
    if (!(Math.floor(time) % 5) || (!current && time) || pausedRef.current) {
      setCurrent(time);
      clearInterval(
        timer.current ||
          setInterval(() => {
            console.log();
          }, 0)
      );
      timer.current = setInterval(() => {
        setCurrent((prevCurrent) => prevCurrent + 1);
      }, 1000);
      pausedRef.current = false;
    }
  }, [time, playing]);

  useEffect(() => {
    if (!playing) {
      pausedRef.current = true;
      clearInterval(
        timer.current ||
          setInterval(() => {
            console.log();
          }, 1)
      );
    }
  }, [playing]);

  useEffect(() => {
    setCurrent(0);
  }, [id]);

  return {
    id,
    current,
  };
};

export type UsePlaylistReturn = ReturnType<typeof usePlaylist>;
export type UseNowPlayingReturn = ReturnType<typeof useNowPlaying>;

export default usePlaylist;
