import { map } from 'lodash';
import { HIGHLIGHTED_TEXT_PROPS, LYRIC_TEXT_LINEHEIGHT } from 'constans/konva';
import Konva from 'konva';

export const createTextLine = (text, props = {}) =>
  new Konva.Text({
    ...HIGHLIGHTED_TEXT_PROPS,
    text,
    ...props,
  });

export const clearStage = (stage, autoDraw = true) => {
  stage.destroyChildren();

  autoDraw && stage.draw();
  // console.log('Konva:draw clearStage')
};

export const setText = (stage, text, props) => {
  const textBox = createTextLine(text, props);
  stage.add(textBox);
  return textBox;
};

export const createKonvaVerse = (
  stage,
  { index, text, textLines, words, numberOfLines },
  { withText = false, textProps = {} } = {}
) => {
  if (!stage) return;

  const height = stage.height();
  const width = stage.width();
  const hCenter = height / 2;
  const vCenter = width / 2;
  const testTextBox = createTextLine('', { ...textProps });
  const gHeight =
    (testTextBox.measureSize('text').height + LYRIC_TEXT_LINEHEIGHT) *
    numberOfLines;
  const id = `verse_${index}`;

  // Check id this group verse already exist, do a rerender
  const groupExist = stage.findOne(`#${id}`);
  if (groupExist) {
    groupExist.to({
      width,
      height: gHeight,
      y: hCenter - gHeight / 2,
      duration: 0,
    });
  }
  const group = groupExist
    ? groupExist
    : new Konva.Group({
        id,
        width,
        height: gHeight,
        y: hCenter - gHeight / 2,
        opacity: 1,
      });

  // If text nodes exist do rerender
  if (group.hasChildren()) {
    const textBoxes = group.getChildren();
    let lineIndex = 0;
    textBoxes.each((textBox) => {
      const textLine = textLines[lineIndex];
      const t = testTextBox.measureSize(textLine.join(' '));
      textBox.to({
        width,
        x: vCenter - t.width / 2,
        y: (t.height + LYRIC_TEXT_LINEHEIGHT) * lineIndex,
        ...textProps,
      });
      lineIndex += 1;
    });
    return { textBoxes, group };
  }

  const textBoxes = map(textLines, (textLine, lineIndex) => {
    const t = testTextBox.measureSize(textLine.join(' '));
    const textBox = createTextLine(withText ? textLine.join(' ') : '', {
      width,
      x: vCenter - t.width / 2,
      y: (t.height + LYRIC_TEXT_LINEHEIGHT) * lineIndex,
      ...textProps,
    });

    group.add(textBox);
    return textBox;
  });

  stage.add(group);
  return { textBoxes, group };
};
