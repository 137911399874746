// import { Component } from 'react'
import { Center, Spinner } from '@careaoke/ui'
import { Song } from '@careaoke/types'
// eslint-disable-next-line
export interface SongListProps {
  songs: Song[],
  renderItem: (song: Song, i: number) => void
  loading: boolean
}

export const SongList = ({songs, renderItem, loading}: SongListProps) => {
  if (loading) return <Center style={{padding: 10}}><Spinner size="xl"/></Center>
  return (
    <>
      {songs.map((song, i) => {
        return renderItem(song, i)
      })}
    </>
  )
};

export default SongList;
