import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import ImageUploader from "react-images-upload"

import { 
  Box,
  Center,
  Footer,
  Button,
  Text,
  Input,
  Textarea,
  DatePicker,
  NumberInput,
  HStack,
  NumberInputField,
  FormControl,
  FormLabel,
  Image
} from '@careaoke/ui'
import { useCreateEvent } from '@careaoke/api';
import styles from '../../app.module.scss';


export const CreateEvent = () => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<string>()
  const { save, canSave, event, setEvent } = useCreateEvent()
  const toast = useToast()
  const history = useHistory()

  const onDrop = (image: any) => {
    if (!image[0]) return
    const blob = new Blob(image, {type: image[0].type})
    const url = URL.createObjectURL(blob)
    setImage(url)
    handleChange('image', blob)
  };

  const handleChange = (type: string, val: any) => {
    setEvent({...event, [type]: val})
  }

  const handleSave = async () => {
    setLoading(true)
    const id = await save()
    setLoading(false)
    if (id) {
      history.push(`/player/${id}`)
    } else {
      toast({
        title: "Something went wrong, please try again.",
        status: 'error',
        duration: 2000
      })
    }
  }

  useEffect(() => {
    handleChange('startdate', Date.now())
  }, [])

  return (
    <FormControl isRequired>
      <Box className={styles.layout}>
        <Box pt="2" w="100%">
          <Center>
            <Box textAlign="center">
              <Text fontWeight={'bold'} fontSize="2em">Get The Party Started</Text>
              <Text>Start a new karaoke party with an empty song queue.</Text>
            </Box>
          </Center>
        </Box>
        <Box className={styles.layout}>
          {false && <Image htmlWidth="100%" htmlHeight="50%" src={image}/>}
          <Box>
            <FormLabel fontSize="0.7em">Event Name</FormLabel>
            <Input onChange={(e) => handleChange('title', e.target.value)}/>
          </Box>
          <Box pt="1">
            <FormLabel fontSize="0.7em">Description</FormLabel>
            <Textarea onChange={(e) => handleChange('description', e.target.value)}/>
          </Box>
          {false && <Box pt="1">
            <FormLabel fontSize="0.7em">Start Date</FormLabel>
            <DatePicker selectedDate={event?.startdate} onChange={(date: Date) => handleChange('startdate', Date.parse(date.toString()))} />
          </Box>}
          {false && <Box pt="1" >
            <HStack>
              <div>
                <FormLabel fontSize="0.7em">Minimum Donation</FormLabel>
                <NumberInput onChange={(valueString) => handleChange('minimumDonation', parseInt(valueString))}>
                  <NumberInputField />
                </NumberInput>
              </div>
              <div>
                <FormLabel fontSize="0.7em">Target</FormLabel>
                <NumberInput onChange={(valueString) => handleChange('target', parseInt(valueString))}>
                  <NumberInputField />
                </NumberInput>
              </div>
            </HStack>
          </Box>}
        </Box>
        {false && <ImageUploader
          withIcon={true}
          onChange={onDrop}
          imgExtension={[".jpg", ".png", ".jpeg"]}
          maxFileSize={5242880}
          singleImage
        />}
      </Box>
      <Box h="72px"/>
      <Footer>
        <Button isLoading={loading} disabled={loading || !canSave} onClick={handleSave} colorScheme="blue">
          <Text color="white" fontSize="0.7em">Save Event</Text>
        </Button>
      </Footer>
    </FormControl>
  )
}