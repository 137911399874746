import { 
  Menu,
  MenuButton,
  MenuList,
  MenuItem, 
} from '@chakra-ui/react';
import { MyAvatar } from '@careaoke/ui'
import { Song } from '@careaoke/types';
// eslint-disable-next-line
export interface AvatarWithMenuProps {
  isPlaying?: boolean;
  isPlayer?: boolean;
  isMine?: boolean;
  alreadyPlayed?: boolean;
  song: Song;
  actions?: {
    play?: (song: Song) => void;
    buy?: (song: Song) => void;
    bump?: (song: Song) => void;
  };
}

export const AvatarWithMenu = (
  {
    isPlaying = false,
    isMine = false,
    actions = {},
    alreadyPlayed,
    song,
    isPlayer
  }: AvatarWithMenuProps) => {
  return (
    <Menu>
      <MenuButton 
        as={MyAvatar}
        alreadyPlayed={alreadyPlayed}
        user={song.user}
        isMine={isMine}
      />
      {(isPlayer && !isPlaying) &&
      <MenuList >
        {!isPlaying && !alreadyPlayed && 
        <MenuItem
          onClick={() => actions?.bump && actions.bump(song)}
        >
          Bump To Top
        </MenuItem>}
        <MenuItem
          onClick={() => actions?.play && actions.play(song)}
        >
          Play Song Now
        </MenuItem>
      </MenuList>}
    </Menu>
  )
};

export default AvatarWithMenu;
