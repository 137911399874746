import { Song } from '@careaoke/types';
import { useEffect, useState } from 'react';
import { FacetOptions, Hit } from './types';
import { songIndex } from './useAlgolia';
import { useSearchStore } from './useSearchstore';

const facetSongsExpired = ({
  songs,
  timestamp,
}: {
  songs: Song[];
  timestamp: number;
}): boolean => {
  if (!songs) return true;
  if (Date.now() - timestamp > 10 * 60 * 1000) return true;
  return false;
};

export const useFacetSearch = ({
  facet = 'artist_name',
  label,
  doSearch = false,
}: {
  facet: FacetOptions;
  label: string;
  doSearch: boolean;
}) => {
  const [songs, setSongs] = useState<Song[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [facetSongs, setFacetSongs] = useSearchStore((state) => [
    state.facetSongs,
    state.setFacetSongs,
  ]);

  useEffect(() => {
    if (!doSearch) return;
    if (!label || label === '') return;

    if (
      facetSongs[facet] &&
      facetSongs[facet][label] &&
      !facetSongsExpired(facetSongs[facet][label])
    ) {
      setSongs(facetSongs[facet][label].songs);
      return;
    }

    setLoading(true);
    const go = async () => {
      const results = await songIndex.search<Hit>('*', {
        facetFilters: [`${facet}:${label}`],
        hitsPerPage: 1000
      });

      const songs = results.hits?.map((hit) =>
        ({
          name: hit.name,
          duration: hit.duration,
          artistName: hit.artist_name,
          fileUrl: hit.file_url,
          songId: hit.id,
          brandName: hit.creator_brand_name || ''
        } as Song)
      ).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      setSongs(songs);
      setLoading(false);
      setFacetSongs(facet, label, songs);
    };
    go();
  }, [facet, label, doSearch]);

  return { songs, loading };
};
