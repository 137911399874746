// eslint-disable-next-line
export interface UseSearchProps {}

export const useSearch = () => {
  const songs = [{
    title: 'Monday Morning', 
    duration: 185000, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/200/200",
    user: "CFOYjKz5DQZJNyqiFkU3lljUwjy2"
  },{
    title: 'Warm Ways', 
    duration: 135034, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/201/200"
  },{
    title: 'Blue Letter', 
    duration: 182340, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/202/200"
  },{
    title: 'Rhiannon', 
    duration: 123450, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/203/200",
    user: "CFOYjKz5DQZJNyqiFkU3lljUwjy2"
  },{
    title: 'Over My Head', 
    duration: 285000, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/204/200"
  },{
    title: 'Crystal', 
    duration: 123400, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/205/200"
  },{
    title: 'Say You Love Me', 
    duration: 195250, 
    artist: 'Fleetwood Mac',
    image: "http://placekitten.com/206/200"
  }]

  return {
    songs
  }
};

export type UseSearchReturn = ReturnType<typeof useSearch>;

export default useSearch;
