import { useEffect, useCallback } from 'react'
import { useEventDispatch, _EVENTS } from 'utils/EventEmitter'

export const useKeyPress = ({ key: targetKey, onKeyDown, onKeyUp } = {}) => {
  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        onKeyDown()
      }
    },
    [targetKey, onKeyDown]
  )

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        onKeyUp()
      }
    },
    [targetKey, onKeyUp]
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [onKeyDown.onKeyUp]) // Empty array ensures that effect is only run on mount and unmount
}
useKeyPress.defaultProps = {
  key: ' ',
  onKeyDown: () => {},
  onKeyUp: () => {},
}

export const useKeyboardSyncker = (timeRef) => {
  const { dispatchEvent: highlightNextWord } = useEventDispatch(
    _EVENTS.HIGHLIGHT_NEXT_WORD
  )
  const { dispatchEvent: undoHighlight } = useEventDispatch(
    _EVENTS.UNDO_HIGHLIGHT_WORD
  )
  const { dispatchEvent: nextVerse } = useEventDispatch(_EVENTS.NEXT_VERSE)
  // const { dispatchEvent: prevVerse } = useEventDispatch(_EVENTS.PREV_VERSE)

  const onKeyUp = useCallback(() => {}, [])

  useKeyPress({ key: 'ArrowRight', onKeyDown: highlightNextWord, onKeyUp })
  useKeyPress({ key: 'ArrowLeft', onKeyDown: undoHighlight, onKeyUp })
  // useKeyPress({ key: 'ArrowRight', onKeyDown: prevVerse, onKeyUp })
  useKeyPress({ key: 'ArrowDown', onKeyDown: nextVerse, onKeyUp })
  // useKeyPress({ key: 'ArrowUp', onKeyDown: prevVerse, onKeyUp })
}
