import { useState, useMemo } from 'react';
import { FcMusic } from 'react-icons/fc';
import { FaRegTimesCircle, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Song } from '@careaoke/types';

import {
  Box,
  Center,
  Icon,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SongList,
  SongItem,
} from '@careaoke/ui';

import { useSearch } from '@careaoke/api';
import styles from '../../app.module.scss';
import { SongsByFacet } from './SongsByFacet';

export const StandaloneSearch = ({
  modal, 
  modalOnClose
}: {
  modal?: boolean, 
  modalOnClose?: () => void
}) => {
  const history = useHistory()
  const [query, setQuery] = useState('');
  const { songs, loading: songsLoading } = useSearch({ type: 'song', query });

  const buyClicked = (song: Song) => {
    const { songId } = song
    history.push(`/sing/${songId}`)
    modalOnClose && modalOnClose()
  };

  const showFacets = useMemo(() => {
    if (songs.length === 0 && query !== '') return false;
    if (songs.length === 0) return true;
    if (query === '') return true;
    return false;
  }, [query, songs.length]);

  return (
    <Box className={modal ? '' : styles.layout}>
      <Box pt="5" w="100%">
        <Center>
          <Icon boxSize="2em" as={FcMusic} />
        </Center>
      </Box>
      <Box pt="5" textAlign="center">
        <Text fontSize="2em" fontWeight="bold">
          Search Songs
        </Text>
      </Box>
      <Box pt="5">
        <Flex>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} />}
            />
            <Input
              placeholder="Search Songs"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              value={query}
            />
            {query !== '' && (
              <InputRightElement
                children={
                  <Icon as={FaRegTimesCircle} onClick={() => setQuery('')} />
                }
              />
            )}
          </InputGroup>
        </Flex>
      </Box>
      {showFacets ? (
        <SongsByFacet onSongClicked={buyClicked} />
      ) : (
        <Box pt="5">
          <Text fontWeight="bold" style={{ marginBottom: 5 }}>
            Results ({songsLoading ? '...' : songs.length})
          </Text>
          <SongList
            loading={songsLoading}
            songs={songs}
            renderItem={(song: Song, i: number) => {
              return (
                <SongItem
                  key={i}
                  song={song}
                  isFromSearch
                  actions={{ buy: buyClicked }}
                />
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
};
