import './api.module.scss';

/* eslint-disable-next-line */
export interface ApiProps {}

export function Api(props: ApiProps) {
  return (
    <div>
      <h1>Welcome to api!</h1>
    </div>
  );
}

export default Api;
