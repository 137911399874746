import { Text, Group } from 'react-konva';

import { Background } from './Background';
import { Logo } from './Logo';

const Title = ({ text, width, height, ...props }) => {
  const gridHeight = height / 8;
  const titleProps = {
    fill: '#FFFFFF',
    height: 40,
    fontSize: (gridHeight * 2) / 7,
    text,
    align: 'center',
    width,
    ...props,
  };
  return <Text {...titleProps} />;
};

const DEFAULT_TEXT_PROPS = {
  fill: '#ffffff',
  align: 'center',
  verticalAlign: 'center',
  fontStyle: 'bold',
};

export const SongInfo = ({ currentSong = {}, width, height, playing }) => {
  if (playing) return null;
  const gridHeight = height / 8;

  const { name, artistName, albumName } = currentSong;

  const titleProps = {
    ...DEFAULT_TEXT_PROPS,
    width,
    fontSize: (gridHeight * 2) / 2.5,
    height: gridHeight * 2,
    y: gridHeight * 3 + (gridHeight * 2) / 3 / 2,
    text: name,
  };

  const artistProps = {
    ...DEFAULT_TEXT_PROPS,
    width,
    fontSize: (gridHeight * 2) / 4,
    height: 35,
    y: gridHeight * 5.5 + 50,
    text: artistName,
  };

  const albumProps = {
    ...DEFAULT_TEXT_PROPS,
    width,
    fontSize: (gridHeight * 2) / 4,
    height: 35,
    y: gridHeight * 6.5 + 50,
    text: albumName,
  };

  return (
    <Group width={width} height={height}>
      <Background width={width} height={height} />
      <Logo width={width} />
      <Text {...titleProps} />
      <Title text="ARTIST" y={gridHeight * 5.5} width={width} height={height} />
      <Text {...artistProps} />
      {albumName && (
        <>
          <Title
            text="ALBUM"
            y={gridHeight * 6.5}
            width={width}
            height={height}
          />
          <Text {...albumProps} />
        </>
      )}
    </Group>
  );
};
