import React, { useCallback, useEffect, useRef, useState } from 'react';
import { sample } from 'lodash';
import { Circle, Group, Layer, RegularPolygon, Star, Text } from 'react-konva';
import {
  BIG_CANVAS_PROPS,
  COUNTER_SWITCH_COLORS,
  COUNTER_TEXT_PROPS,
} from '../../constans/konva';
import Konva from 'konva';

const getRandomColor = () => {
  return sample(COUNTER_SWITCH_COLORS);
};

const getCenterProps = ({ width, height }) => {
  return {
    x: width / 2,
    y: height / 2,
  };
};

const animateNode = ({
  node,
  color,
  duration = 1,
  onFinish,
  scale: scaleIn,
}) => {
  if (!node) return;

  const scale = scaleIn ? scaleIn : node.scaleX() > 1 ? 0.8 : 1.5;
  const fill = color ? color : getRandomColor();
  node.to({
    scaleX: scale,
    scaleY: scale,
    easing: Konva.Easings.BackEaseIn,
    duration,
    rotation: Math.random() * 360,
    fill,
    onFinish,
  });
};
const animateNodeLast = (props) => animateNode({ ...props, scale: 0.1 });

export const Counter = (props) => {
  const { width, height } = props;
  const layer = useRef();

  return (
    <Layer width={width} height={height} ref={layer}>
      <CounterComponents layer={layer} {...props} />
    </Layer>
  );
};

export const CounterComponents = ({
  width,
  height,
  counter: counterIn,
  onFinish,
  autoStart,
  initText,
  finishText,
  layer,
}) => {
  const counter = useRef(counterIn);
  const groupRef = useRef();
  const figure = useRef();
  const startRef = useRef();
  const circleRef = useRef();
  const text = useRef();
  const [started, setStarted] = useState(false);
  const figureProps = getCenterProps({ width, height });

  const animateText = useCallback(
    ({ text: textIn, onFinish, animation = animateNode }) => {
      if (!figure.current || !text.current) return;

      const node = figure.current;
      text.current.text(textIn);

      animation({ node, onFinish });
    },
    []
  );

  const play = useCallback(() => {
    if (counter.current < 0) {
      counter.current = counterIn;
      // layer.current.hide()
      // setStarted(false)
      if (groupRef.current) groupRef.current.hide();
      return onFinish();
    }

    setStarted(true);

    const text =
      counter.current === counterIn
        ? initText
        : counter.current === 0
        ? finishText
        : `${counter.current}`;

    animateText({
      text,
      onFinish: play,
      animation: counter.current == 0 ? animateNodeLast : animateNode,
    });

    counter.current -= 1;
  }, []);
  const handleStart = useCallback(() => {
    figure.current.to({
      fill: '#096dd9',
      duration: 0.2,
    });
    circleRef.current.to({
      radius: 50,
      strokeWidth: 50,
      duration: 0.2,
    });
    startRef.current.to({
      numPoints: 9,
      duration: 0.5,
      innerRadius: 80,
      opacity: 0.1,
      easing: Konva.Easings.BackEaseIn,
      onFinish: play,
    });
  }, []);

  useEffect(() => {
    if (figure.current && text.current && autoStart) setTimeout(play, 1000);
  }, [figure]);

  return (
    <Group ref={groupRef} id="counter">
      <RegularPolygon
        ref={figure}
        radius={100}
        sides={9}
        fill={!autoStart ? 'transparent' : getRandomColor()}
        opacity={0.9}
        {...figureProps}
      />
      {!autoStart && !started && (
        <>
          <Circle
            ref={circleRef}
            stroke="#096dd9"
            // strokeWidth={14}
            strokeWidth={0}
            radius={116}
            opacity={0.9}
            {...figureProps}
          />
          <Star
            ref={startRef}
            // numPoints={3}
            numPoints={0}
            fill={'#096dd9'}
            innerRadius={50}
            outerRadius={100}
            rotation={90}
            opacity={0.9}
            {...figureProps}
            onTouchStart={handleStart}
            onTap={handleStart}
            onMouseDown={handleStart}
          />
        </>
      )}
      <Text
        text={autoStart ? initText : ''}
        ref={text}
        {...COUNTER_TEXT_PROPS}
        width={width}
        height={height}
        onTouchStart={handleStart}
        onTap={handleStart}
        onMouseDown={handleStart}
      />
    </Group>
  );
};
CounterComponents.defaultProps = {
  ...BIG_CANVAS_PROPS,
  counter: 4,
  onFinish: () => {
    return;
  },
  autoStart: true,
  initText: 'Ready!',
  finishText: 'Go!',
};

Counter.defaultProps = CounterComponents.defaultProps;
