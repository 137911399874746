import { Route, Switch } from "react-router-dom";
import { Event, CreateEvent } from '../pages/Event'
import { Search } from '../pages/Search'
import { Player } from '../pages/Player'
import { StandalonePlayer } from '../pages/StandalonePlayer'
import { Home } from "../pages/Home";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/event/:eventId">
        <Event/>
      </Route>
      <Route path="/player/:eventId">
        <Player/>
      </Route>
      <Route path="/sing/:songId?">
        <StandalonePlayer/>
      </Route>
      <Route path="/search/:eventId">
        <Search/>
      </Route>
      <Route path="/createEvent">
        <CreateEvent/>
      </Route>
      <Route path="/">
        <Home/>
      </Route>
    </Switch>
  )
}