import { useRef, useEffect } from 'react';
import Konva from 'konva';
import { useSettings } from '../../hooks/settings';
import { createKonvaVerse } from '../../utils/konva';
import { get, forEach } from 'lodash';

export const useAnimatedText = ({ currentVerse, layerRef }) => {
  const konvaRefs = useRef();
  const { lyricsText } = useSettings();
  const lyricsTextIn = useRef(lyricsText);

  const renderKonvaVerse = () => {
    if (!layerRef.current) return;
    const layer = layerRef.current;

    if (!currentVerse) return;
    const currentPosition = currentVerse.index;

    const [container] = layer.getChildren(
      (node) => node.getId() === 'base-lyric'
    );
    if (!container) return;

    const textProps = lyricsTextIn.current;
    konvaRefs.current = createKonvaVerse(container, currentVerse, {
      withText: true,
      textProps,
    });

    const { group } = konvaRefs.current;
    group.y(group.y() + 24);

    container.draw();

    new Konva.Tween({
      node: group,
      y: group.y() - 24,
      opacity: 1,
      duration: 0.4,
    }).play();

    return () => {
      const nodes = container.getChildren(
        (node) => node.getId() === `verse_${currentPosition}`
      );
      // node.destroy()
      forEach(nodes, (node) => {
        new Konva.Tween({
          node: node,
          y: node.y() - 24,
          opacity: 0,
          duration: 0.4,
          onFinish: () => {
            node.destroy();
            layer.draw();
          },
        }).play();
      });
    };
  };

  const clearStage = () => {
    if (!konvaRefs.current) return;

    const { group } = konvaRefs.current;
    group.destroy();
    layerRef.current.draw();
  };

  const reRenderKonvaVerse = () => {
    clearStage();
    renderKonvaVerse();
  };

  const text = get(currentVerse, 'text');
  useEffect(() => {
    lyricsTextIn.current = lyricsText;
    reRenderKonvaVerse();
  }, [text, layerRef, lyricsText]);

  return true;
};
