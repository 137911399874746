import { useEffect, useState } from 'react';
import { FacetDict, FacetOptions, FacetData } from './types';
import { songIndex } from './useAlgolia';
import { useSearchStore } from './useSearchstore';
import { artistData } from './data'

interface UseSearchProps {
  facet?: FacetOptions;
  value?: string;
  hitsPerPage?: number;
}

const facetExpired = (facetData: { data?: FacetDict; timestamp: number }) => {
  if (!facetData) return true;
  if (!facetData.data) return true;
  if (Date.now() - facetData.timestamp > 10 * 60 * 1000) return true;
  return false;
};

const DEFAULT_SEARCHFACETS_OPTIONS = {
  facet: 'artist_name',
  hitsPerPage: 1,
} as UseSearchProps;

export const useSearchFacets = ({
  facet = 'artist_name',
  hitsPerPage = 1,
}: UseSearchProps = DEFAULT_SEARCHFACETS_OPTIONS) => {
  const [facetData, setFacetData] = useState<FacetDict>({});
  const [loading, setLoading] = useState<boolean>(false);
  const regex = new RegExp('[A-Za-z]')

  const [storedFacets, setStoredFacets] = useSearchStore((state) => [
    state.facets,
    state.setFacets,
  ]);

  useEffect(() => {
    setLoading(true)
    if (!facetExpired(storedFacets[facet])) {
      setFacetData(storedFacets[facet].data || {});
      setLoading(false);
      return;
    }

    const go = async () => {
      let facets
      if (facet === 'artist_name') {
        facets = JSON.parse(artistData) as FacetData
      } else {
        const results = await songIndex.search('*', {
          facets: [facet],
          hitsPerPage,
        });
        facets = results?.facets as FacetData
      }

      if (!facets || !facets[facet]) return;

      const facetData = facets[facet];

      const processedData = Object.keys(facetData).reduce<FacetDict>((obj, label) => {
        const letter = label.charAt(0).toLowerCase();
        const key = regex.test(letter) ? letter : '...'
        
        if (!obj[key]) {
          obj[key] = [];
        }

        obj[key].push({
          label,
          count: facetData[label] as number,
        });
        
        obj[key].sort((a, b) => {
          return a.label.localeCompare(b.label)
        });

        return obj;
      }, {});
      setFacetData(processedData);
      setStoredFacets(facet, processedData);
      setTimeout(() => {setLoading(false)}, 0)
    };
    go();
  }, [facet, hitsPerPage]);

  return { facetData, loading };
};
