import { useAuthState } from 'react-firebase-hooks/auth'

import { auth } from '../../index'

export const useAuth = () => {
  const [user] = useAuthState(auth)
  
  const signIn = () => {
    auth.signInAnonymously()
  }
  
  const signOut = () => {
    auth.signOut()
  }

  return {
    signIn,
    signOut,
    user
  }
};