import { useEffect, useState } from 'react';
import { Image } from 'react-konva';

import * as logo from './golden-chorale-logo-3d.png';
const NATURAL_SIZE = { width: 586, height: 405, ratio: 405 / 586 };

export const Logo = ({ width, height, ...props }) => {
  const [image, setImage] = useState();
  useEffect(() => {
    const im = new window.Image();
    im.src = logo;
    im.addEventListener('load', () => {
      setImage(im);
    });
  }, []);

  const logoWidth = width / 2 > 500 ? 500 : width / 2;

  return (
    <Image
      image={image}
      width={logoWidth}
      height={logoWidth * NATURAL_SIZE.ratio}
      x={width / 2 - logoWidth / 2}
      y={50}
      {...props}
    />
  );
};
