import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';

import {
  useSong,
} from '@careaoke/api';

import { 
  Flex, 
  Button, 
  Icon
} from '@careaoke/ui'

import './styles.scss';

import { Player } from './Player';
import { YoutubePlayer } from './YoutubePlayer';

export const StandaloneLayoutPlayer = (props) => {
  const { songId } = useParams()
  const [selectedSong, setSelectedSong] = useState({})
  const { getSong } = useSong()

  useEffect(() => {
    const fireGetSong = async () => {
      setSelectedSong(await getSong(songId))
    }
    if (songId)
      fireGetSong()
  }, [songId])

  const { fileUrl } = selectedSong;

  const Component = useMemo(() => {
    return fileUrl?.includes('.kr') ? Player : YoutubePlayer
  }, [fileUrl])

  return (
    <Component
      selectedSong={selectedSong}
      renderToolbar={() => (
        <Flex 
          gridGap={4} 
          alignItems="center" 
          justifyContent="center" 
        >
          <Button
            leftIcon={<Icon as={MdSearch} fontSize={32} color="black" />}
            onClick={props.onOpen}
          >
            Search
          </Button>
        </Flex>
      )}
      onUpdateTime={() => {return}}
      onPlay={() => {return}}
      onPause={() => {return}}
      onMusicStop={() => {return}}
      setSongFinished={() => {return}}
    />
  );
};
