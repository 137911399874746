import { useParams } from 'react-router-dom'
import { HStack, Flex, Spacer } from '@chakra-ui/react'
import { Progress, ProgressProps, Text } from '@careaoke/ui'
import { useNowPlaying } from '@careaoke/api'
import { Song } from '@careaoke/types'
import { useFormatTime } from '../../hooks/use-format-time/useFormatTime';

// eslint-disable-next-line
export interface SongProgressProps extends ProgressProps {
  current: number,
  fontSize?: string,
  showSongTitle?: boolean,
  song: Song
}

export const SongProgress = ({
  song,
  current, 
  fontSize = "0.9em", 
  showSongTitle = false,
  ...props
}: SongProgressProps) => {
  const { eventId }: {eventId: string} = useParams()
  const { duration, name } = song
  // const _current = current * 1000 // not yet sure if this will be seconds or milliseconds
  // const { current } = useNowPlaying(eventId)
  const currentFormatted = useFormatTime(current * 1000)
  const durationFormatted = useFormatTime(duration)
  return (
    <>
      <Flex>
        <Text 
        fontSize={fontSize}
        >
          {showSongTitle && name}
        </Text>
        <Spacer/>
        <HStack 
        spacing="4px" 
        justify="flex-end" 
        style={{marginBottom: 5}}
        {...props}
        >
          <Text 
          fontSize={fontSize}
          >
            {currentFormatted}
          </Text>
          <Text
          fontSize={fontSize}
          opacity='0.5'
          >
            /
          </Text>
          <Text
          opacity='0.5'
          fontSize={fontSize}
          >
            {durationFormatted}
          </Text>
        </HStack>
      </Flex>
      <Progress
      size="sm"
      max={duration}
      value={current * 1000}
      />
    </>
  )
};

export default SongProgress;
