import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";

import App from './app/app';
import { UiProvider } from '@careaoke/ui';
import { Hooks } from 'hooks/index'

ReactDOM.render(
  <StrictMode>
    <UiProvider>
      <BrowserRouter>
        <Hooks>
          <App />
        </Hooks>
      </BrowserRouter>
    </UiProvider>
  </StrictMode>,
  document.getElementById('root')
);
