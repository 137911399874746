import { useCollectionData } from 'react-firebase-hooks/firestore'

import { firestore } from '../../index'

export const useFirestoreValues = ({ collection }: { collection: string }) => {
  const [values] = useCollectionData(
    firestore.collection(collection),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  return {
    values
  }
};