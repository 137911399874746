import { useState, useMemo, useCallback, useEffect } from 'react';

import { debounce } from 'lodash';
import { Song } from '@careaoke/types';
import { songIndex } from './useAlgolia';
import { Hit } from './types';

export const useSearch = ({ type, query }: { type: string; query: string }) => {
  const [data, setData] = useState({ songs: [] as Song[] });
  const [loading, setLoading] = useState(false);

  const doSearch = useCallback(() => {
    if (!query || query === '') return;
    if (!type) return;
    songIndex
      .search<Hit>(query, {
        page: 0,
        facets: ['*'],
        hitsPerPage: 20,
      })
      .then((result) => {
        const { hits } = result;
        const songs = hits.map((hit) =>
          ({
            name: hit.name,
            duration: hit.duration,
            artistName: hit.artist_name,
            fileUrl: hit.file_url,
            songId: hit.id,
            brandName: hit.creator_brand_name || ''
          } as Song)
        );
        setData({ songs });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, query]);
  const throttledSearch = useMemo(() => debounce((fn) => fn(), 500), []);

  useEffect(() => {
    setLoading(true);
    throttledSearch(doSearch);
  }, [doSearch, throttledSearch]);

  return {
    loading,
    ...data,
  };
};
