import { songIndex } from './useAlgolia';
import { Hit } from './types';
import { Song } from '@careaoke/types';

export const useSong = () => {
  const getSong = async (id: string) => {
    const song = await songIndex.getObject<Hit>(id)
    return {
      name: song.name,
      duration: song.duration,
      artistName: song.artist_name,
      fileUrl: song.file_url,
      songId: song.id,
      brandName: song.creator_brand_name || ''
    } as Song
  }
  return {
    getSong
  }
};
