import { Rect } from 'react-konva';

export const PlayComponent = ({ height, width, showActions }) => {
  return (
    <Rect
      height={height}
      width={width}
      onTouchStart={showActions}
      onTap={showActions}
      onMouseDown={showActions}
    />
  );
};
