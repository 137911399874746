import { useState, useEffect } from 'react'
import { useFacetSearch, useSearchFacets } from '@careaoke/api';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useAccordionItemState,
  ButtonGroup,
  Box,
  Text,
} from '@chakra-ui/react';
import { Center, SongItem, SongList, Spinner, Button } from '@careaoke/ui';
import { Song } from '@careaoke/types';
import { FacetOptions, FacetNames, FacetDict } from 'libs/api/src/lib/useSearch/types';

interface SongsByFacetProps {
  noop?: string;
}

const FacetSongs = ({
  label,
  onSongClicked,
  facet
}: {
  label: string;
  onSongClicked: (song: Song) => void;
  facet: FacetOptions
}) => {
  const { isOpen } = useAccordionItemState();
  const { songs, loading } = useFacetSearch({
    facet,
    label,
    doSearch: isOpen,
  });
  
  if (!isOpen) {
    return null;
  }
  
  return (
    <SongList
      loading={loading}
      songs={songs}
      renderItem={(song: Song, i: number) => {
        return (
          <SongItem
            key={i}
            song={song}
            isFromSearch
            actions={{ buy: onSongClicked }}
          />
        );
      }}
    />
  );
};

const FacetCategories = ({
  data,
  onSongClicked,
  facet
}: {
  data: any[];
  onSongClicked: (song: Song) => void;
  facet: FacetOptions
}) => {

  const { isOpen } = useAccordionItemState();
  if (!isOpen) {
    return null;
  } 

  return (
    <>
      {data.map((item) => (
        <AccordionItem id={item.label} key={item.label}>
          <AccordionButton as="div">
            <Box flex="1" textAlign="left">
              {item.label} ({item.count})
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <FacetSongs
              label={item.label}
              onSongClicked={onSongClicked}
              facet={facet}
            />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </>
  );
};

export const SongsByFacet = ({
  onSongClicked,
}: {
  onSongClicked: (song: Song) => void;
}) => {
  const [shouldBeLoading, setShouldBeLoading] = useState(true)
  const [facet, setFacet] = useState<FacetOptions>('artist_name')
  const { facetData, loading } = useSearchFacets({facet});

  useEffect(() => {
    setShouldBeLoading(loading)
  }, [loading])

  if (shouldBeLoading || loading)
    return (
      <Center style={{ padding: 10 }}>
        <Spinner size="xl" />
      </Center>
    );

    return (
      <Box pt="5">
        <ButtonGroup size="sm" width="100%" isAttached variant="outline">
          {FacetOptions.map((facetOpt) => {
            return (
              <Button 
              key={facetOpt}
              isActive={facet === facetOpt} 
              onClick={() => {setShouldBeLoading(true);setFacet(facetOpt)}} 
              width="100%">
                {FacetNames[facetOpt]}
              </Button>
            )
          })}
        </ButtonGroup>
        {facet === 'artist_name' ? 
        <Accordion allowMultiple reduceMotion pt="5">
          {Object.keys(facetData)
            .sort()
            .map((letter) => (
              <AccordionItem id={letter} key={letter}>
                <AccordionButton id={letter} as="div">
                  <Text
                    top={0}
                    position="sticky"
                    as="h3"
                    paddingY="2"
                    paddingX="4"
                    background="blue.500"
                    textColor="white"
                    borderRadius="sm"
                    marginBlockStart="0"
                    marginLeft="0"
                    fontWeight="bold"
                    fontSize="large"
                    width="100%"
                  >
                    {letter.toUpperCase()}
                  </Text>
                </AccordionButton>
                <AccordionPanel>
                  <FacetCategories 
                  data={facetData[letter]}
                  onSongClicked={onSongClicked}
                  facet={facet}
                  />
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion> : 
        <Accordion allowToggle={true} reduceMotion={true} pt="5">
        {Object.keys(facetData)
          .sort()
          .map((letter) => (
            <Box key={letter}>
              <Text
                top={0}
                position="sticky"
                as="h3"
                paddingY="2"
                paddingX="4"
                background="blue.500"
                textColor="white"
                borderRadius="sm"
                marginBlockStart="0"
                marginLeft="0"
                fontWeight="bold"
                fontSize="large"
              >
                {letter.toUpperCase()}
              </Text>
              {facetData[letter].map((item) => (
                <AccordionItem key={item.label}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {item.label} ({item.count})
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <FacetSongs
                      label={item.label}
                      onSongClicked={onSongClicked}
                      facet={facet}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Box>
          ))}
      </Accordion>}
      </Box>
    );
};