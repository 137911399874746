import { Song } from '@careaoke/types'
// import { Song } from '@careaoke/ui'
import { Flex, HStack, Image, Spacer, Text, VStack, Center } from '@chakra-ui/react';

// eslint-disable-next-line
export interface SongTitleProps {
  song: Song, 
  isPlaying?: boolean,
  children?: React.ReactNode,
  showImage?: boolean
  alreadyPlayed?: boolean
}

export const SongTitle = ({
  song, 
  isPlaying = false, 
  showImage = true, 
  alreadyPlayed = false,
  children
} : SongTitleProps) => {

  return (
    <Flex>
      <HStack 
        spacing={4} 
        opacity={alreadyPlayed ? 0.5 : 1}
      >
        {(showImage && song.image) && 
        <Image src={song.image} boxSize="50px"/>}
        <VStack align="left" spacing={1}>
          <Text
            fontWeight="bold"
            color={isPlaying ? '#3182CE' : ''}
          >
            {song.name}
          </Text>
          <Text
            fontSize="0.5em"
            style={{opacity: 0.5}}
          >
            {song.artistName} — {song.brandName ? song.brandName : 'Careaoke'}
          </Text>
        </VStack>
      </HStack>
      <Spacer/>
      {children && <Center>{children}</Center>}
    </Flex>
  )
};

export default SongTitle;
