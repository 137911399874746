import { useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom'

import styles from './app.module.scss';
import { useAuth } from '@careaoke/api';
import { Routes } from './router';

export function App () {
  const mounted = useRef(false)
  const { signIn } = useAuth()
  useEffect(() => { // log-in anonymously
    if (!mounted.current) {
      signIn()
      mounted.current = true
    }
  }, [signIn])

  return (
    <div className={styles.app}>
      <Routes/>
    </div>
  );
}

export default App;
